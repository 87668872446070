import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Log } from '@app/property/log';
import { lastValueFrom } from 'rxjs';


@Injectable()
export class LogService extends ApiService {
  constructor(protected override http: HttpClient) {
    super(http);
  }

  async getLogs(): Promise<Log[]> {    
    const options: object = {
      withCredentials: true,
      headers: new HttpHeaders({}),
    };
    try {
      const response = this.http
        .get<any>(this.url + `logs/`, options);        
      const result = await lastValueFrom(response);      
      return result.data as Log[];
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

}
