import { ApiResponse, ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@app/service/auth.service';
import { lastValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductGroup } from '@app/property/productgroup';
import { NotificationService } from '@app/service/notification.service';
import { Product } from '@app/property/product';

@Injectable()
export class MyProductGroupService extends ApiService {
  constructor(
    protected override http: HttpClient,
    private authService: AuthService,
    private notificationService: NotificationService,
  ) {
    super(http);
  }

  /**
   * Gets all product groups with products and blocks.
   * @param id 
   * @returns all from products 
   */
  getWithInjection(): Observable<ProductGroup[]> {
    return this.http
      .get<any>(`${this.url}myproductgroup/withinjection`, this.options)
      .pipe(map(result => result.data))
  }

  /**
   * Gets all product groups with products and blocks. Blocks are not modified.
   * @param id 
   * @returns all from products 
   */
  getAll(): Promise<ProductGroup[]> {
    return this.http
      .get<any>(`${this.url}myproductgroup`, this.options)
      .toPromise()
      .then((response) => {
        const pgs = Array.prototype.map.call(response.data, (pg) => {
          return pg;
        }) as ProductGroup[];
        return Promise.resolve(pgs);
      });
  }

  async save(form: ProductGroup): Promise<ProductGroup> {
    const formData: any = form;
    if (formData._id) {
      return this.http
        .put<ApiResponse>(`${this.url}myproductgroup/${formData._id}`, formData, this.options)
        .toPromise()
        .then((response) => {
          this.notificationService.showNotification('Die Produktgruppe wurde gespeichert');
          return Promise.resolve(response.data as ProductGroup);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    } else {
      return this.http
        .post<ApiResponse>(`${this.url}myproductgroup`, formData, this.options)
        .toPromise()
        .then((response) => {
          this.notificationService.showNotification('Die Produktgruppe wurde angelegt');
          return Promise.resolve(response.data as ProductGroup);
        })
        .catch((error) => {
          console.error(error);
          return Promise.reject(error);
        });
    }
  }

  getById(id: string): Promise<ProductGroup> {
    return this.http
      .get<ApiResponse>(`${this.url}myproductgroup/${id}`, this.options)
      .toPromise()
      .then((response) => {
        const productGroup = response.data as ProductGroup;
        return Promise.resolve(productGroup);
      });
  }

  delete(id: string): Promise<boolean> {
    return this.http
      .delete(`${this.url}myproductgroup/${id}`, this.options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(true);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  saveSorting(productGroups: ProductGroup[]): Promise<boolean> {
    return this.http
      .put<ApiResponse>(`${this.url}sort/myproductgroups`, productGroups, this.options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(true);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async convertToMyGroup(productGroup: ProductGroup): Promise<ProductGroup> {
    try {
      const response = this.http
        .post<any>(this.url + `copyto/myproductgroup`, productGroup, this.options);
      
      const result = await lastValueFrom(response);
      
      if (!result) { return undefined; }

      return result.data as ProductGroup;
    } catch (error) {
      return undefined;
    }
  }

  async restoreToOriginal(productGroup: ProductGroup): Promise<Product> {
    try {
      const response = this.http
        .post<any>(this.url + `restore-original/myproductgroup`, productGroup, this.options);
      
      const result = await lastValueFrom(response);
      
      if (!result) { return undefined; }

      return result.data as Product;
    } catch (error) {
      return undefined;
    }
  }
}
