import { Component, OnInit } from '@angular/core';
import { AppConfigService } from '@app/service/app-config.service';

@Component({
    selector: 'app-app',
    templateUrl: './app.layout.guest.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppLayoutGuestComponent implements OnInit{
    isSafeMode = false;

    constructor(
        private appConfigService: AppConfigService,
    ) {
        
    }

    async ngOnInit() {
        this.isSafeMode = await this.appConfigService.getSafeModeStatus();
    }
}


