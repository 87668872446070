import { ApiResponse, ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { OfferTemplate } from '../property/offertemplate';
import { Blockgroup } from '../property/blockgroup';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { NotificationService } from '@app/service/notification.service';
import { Block } from '../property/block';
import { AuthService } from './auth.service';

@Injectable()
export class OfferTemplateService extends ApiService {
  constructor(
    protected override http: HttpClient,
    private notificationService: NotificationService,
    private authService: AuthService
  ) {
    super(http);
  }

  getById(id: string): Promise<OfferTemplate> {
    return this.http
      .get<ApiResponse>(`${this.url}offertemplates/${id}`, this.options)
      .toPromise()
      .then((response) => {
        //const tmp: any = Object.assign({}, response.data);
        const offerTemplate = response.data as OfferTemplate;
        //offerTemplate.contact = JSON.parse(tmp.contact);
        /* Convert to blocks */
        offerTemplate.blocks = offerTemplate.blocks.map(b => Block.fromJSON(b));
        return Promise.resolve(offerTemplate);
      });
  }

  getAll(options = {}): Promise<OfferTemplate[]> {
    let params = null;
    if (options) {
      params = Object
        .keys(options)
        .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(typeof options[k] === 'object' ? JSON.stringify(options[k]) : options[k])}`)
        .join('&');
    }

    return this.http
      .get<ApiResponse>(`${this.url}offertemplates/${params !== '' ? '?' + params : ''}`, this.options)
      .toPromise()
      .then((response) => {
        const offerTemplates = response.data as OfferTemplate[];
        return Promise.resolve(offerTemplates);
      });
  }

  async save(offerTemplate: OfferTemplate): Promise<OfferTemplate> {
    if (offerTemplate._id){
      await this.log(offerTemplate._id, 'update', offerTemplate);
      return this.http
      .put<ApiResponse>(`${this.url}offertemplates/${offerTemplate._id}`, offerTemplate, this.options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response.data as OfferTemplate);
      })
      .catch((error) => {
        this.notificationService.showNotification(error.error.errors);
        return Promise.reject(error);
      });
    } else {
      return this.http
      .post<ApiResponse>(`${this.url}offertemplates/`, offerTemplate, this.options)
      .toPromise()
      .then((response) => {
        this.log(response.data['_id'], 'create'); 
        return Promise.resolve(response.data as OfferTemplate);
      })
      .catch((error) => {
        this.notificationService.showNotification(error.error.errors);
        return Promise.reject(error);
      });
    }
  }

  getAllBlockgroups(): Promise<Blockgroup[]> {
    return this.http
      .get<ApiResponse>(`${this.url}blockgroups/`, this.options)
      .toPromise()
      .then((response) => {
        const blockgroups = response.data as Blockgroup[];
        return Promise.resolve(blockgroups);
      });
  }

  getAllFromBin(): Promise<OfferTemplate[]> {
    return this.http
      .get<ApiResponse>(`${this.url}bin/offertemplates`, this.options)
      .toPromise()
      .then((response) => {
        const templates = response.data as OfferTemplate[];
        return Promise.resolve(templates);
      });
  }

  markToDeleteById(id: string) {
    return this.http
      .put(`${this.url}bin/offertemplates/${id}`, { toDelete: true }, this.options)
      .toPromise()
      .then((response) => {
        this.log(id, 'delete');
        return Promise.resolve(true);
      });
  }

  unMarkToDeleteById(id: string) {
    return this.http
      .put(`${this.url}bin/offertemplates/${id}`, { toDelete: false }, this.options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(true);
      });
  }

  deleteById(id: string) {
    return this.http
      .delete(`${this.url}bin/offertemplates/${id}`, this.options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(true);
      });
  }

  async log(offerTemplateId, logtype, newOfferTemplate = null, versionId = null){

    const currentUser = await this.authService.getCurrentUser();

    const reqBody = {
      logtype,
      currentuser: currentUser._id,
      logoffertemplateid: offerTemplateId,
      newOfferTemplate,
      versionId, 
    }

    return this.http
      .post<ApiResponse>(`${this.url}offertemplatelog/`, reqBody, this.options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        console.log(error.error.errors);
        return Promise.reject(error.error.errors);
      });

  }

  getLog(offertemplateid) {
    return this.http
      .get<ApiResponse>(`${this.url}offertemplatelog/${offertemplateid}`, this.options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response.data);
    });
  }

  
  async restoreVersion(versionId, offerTemplateId) {

    await this.log(offerTemplateId, 'restore', null, versionId);

    const reqBody = {
      versionId,
      offerTemplateId,
    }

    return this.http
      .post<ApiResponse>(`${this.url}offertemplaterestore/`, reqBody, this.options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        console.log(error.error.errors);
        return Promise.reject(error.error.errors);
      });

  }

}
