import { Injectable } from '@angular/core';
import {ApiResponse, ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import { AppConfig } from '@app/property/appconfig';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService extends ApiService {

  constructor(
    protected override http: HttpClient,
    private notificationService: NotificationService,

  ) {
    super(http);
  }

  getCurrent(): Promise<AppConfig> {
    return this.http
    .get<ApiResponse>(`${this.url}appconfig/current`, this.options)
    .toPromise()
    .then((response) => {
      const appConfig = response.data as AppConfig;
      return Promise.resolve(appConfig);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
  }

  save(data: AppConfig): Promise<AppConfig> {
    return this.http
      .put<ApiResponse>(`${this.url}appconfig/current`, data, this.options)
      .toPromise()
      .then((response) => {
        this.notificationService.showNotification('Änderungen wurden gespeichert');
        return Promise.resolve(response.data as AppConfig);
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  }
  
  getSafeModeStatus(): Promise<boolean> {
    return this.http
    .get<ApiResponse>(`${this.url}appconfig/safemode`, this.options)
    .toPromise()
    .then((response) => {
      const status = response.data as boolean;
      return Promise.resolve(status);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
  }
}
