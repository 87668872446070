import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Offer } from '@app/property/offer';
import { Block } from '@app/property/block';
import * as _ from 'lodash';
import { Page } from '@app/property/page';
import { ContactPerson } from '@app/property/contactperson';
import { Media } from '@app/property/media';
import { User } from '@app/property/user';
import { MyProductService } from '@app/service/myproduct.service';
import { MyProductGroupService } from '@app/service/myproductgroup.service';
import { AuthService } from '@app/service/auth.service';
import { Company } from '@app/property/company';
import * as moment from 'moment';
import { Product } from '@app/property/product';
import { ProductGroup } from '@app/property/productgroup';
import { Role } from '@app/property/role';
import { DataPdf } from '@app/property/datapdf';
import { BlockEventService } from './block-event.service';
import { environment } from '@app/../environments/environment';
import { CompanyService } from '@app/service/company.service';
import { OfferDetailPageService } from './offer-detail-page.service';

@Injectable()
export class OfferDetailService {

  private userAnnounced = new Subject<User>();
  public userAnnounced$ = this.userAnnounced.asObservable();

  // TODO subscribers are not notified => changes in contact and information are not displayed
  private offerAnnounced = new Subject<Offer>();
  public offerAnnounced$ = this.offerAnnounced.asObservable();

  private updateContactAnnounced = new Subject<ContactPerson>();
  public updateContactAnnounced$ = this.updateContactAnnounced.asObservable();

  // private updateContactPersonAnnounced = new Subject<string>();
  // public updateContactPersonAnnounced$ = this.updateContactPersonAnnounced.asObservable();

  private updateDateAnnounced = new Subject<string>();
  public updateDateAnnounced$ = this.updateDateAnnounced.asObservable();

  private updateCatCoverpageAnnounced = new Subject<Product[]>();
  public updateCatCoverpageAnnounced$ = this.updateCatCoverpageAnnounced.asObservable();

  private updateCatOfferAnnounced = new Subject<Product[]>();
  public updateCatOfferAnnounced$ = this.updateCatOfferAnnounced.asObservable();

  private updateCatWritetoAnnounced = new Subject<Product[]>();
  public updateCatWritetoAnnounced$ = this.updateCatWritetoAnnounced.asObservable();

  private updateCatFormAnnounced = new Subject<Product[]>();
  public updateCatFormAnnounced$ = this.updateCatFormAnnounced.asObservable();

  offer: Offer;
  company: Company = null;

  public productGroups: Observable<ProductGroup[]>;
  roles: Role[];

  unsavedChanges: boolean;
  offerNumberIsInvalid = false;

  private defaultVariables: any = {
    Responsible: {},
    Contact: {},
    Offer: { price: '0.00' },
    Company: {},
    User: {},
  };
  public paramsType: string;
  public paramsAction: string;

  public coverImage: File;
  public coverImageBase64: string;

  private url: string = environment.api_url;

  public designVariant: string;

  constructor(
    private authService: AuthService,
    private myProductService: MyProductService,
    private myProductGroupService: MyProductGroupService,
    private blockEventService: BlockEventService,
    private companyService: CompanyService,
    private offerDetailPageService: OfferDetailPageService,
  ) {
    this.productGroups = this.myProductGroupService.getWithInjection();

    // this.paramsAction = 'create';
    // this.paramsType = 'offer';

    this.designVariant = 's';

  }
  /**
   * Gets company and sets default variables, design variant and roles (side effects)
   * @returns  
   */
  async getCompany() {
    const { company, firstname, lastname, position, fax, phone, mobile, email, roles, avatar, signature } = await this.authService.getCurrentUser();
    if (!company) {
      this.company = await this.companyService.getOfferCompany(this.offer._id);
    } else {      
      this.company = company;
    }
    this.defaultVariables.Company = this.company;
    this.designVariant = this.company.blockDesignVariant;
    this.defaultVariables.User = {
      firstname,
      lastname,
      position,
      fax,
      phone,
      mobile,
      email,
      avatar,
      signature,
    };
    this.roles = roles;
    return company;
  }
  /**
   * Clears elements
   */
  clearElements(): void {
    this.offer = null;
    this.unsavedChanges = false;
    Page.PAGECOUNTER = 0;
  }

  /**
   * Sets current user
   * @param user 
   */
  setCurrentUser(user: User) {
    this.userAnnounced.next(user);
  }
  /**
   * Gets default variable
   * @param key 
   * @returns default variable 
   */
  getDefaultVariable(key: string) {
    const keys = key.split('.');
    function index(obj, i) { return obj[i]; }
    if (
      keys
      && keys.length > 1
      && this.defaultVariables[keys[0]]
      && this.defaultVariables[keys[0]][keys[1]]
    ) {
      //console.log(keys.reduce(index, this.defaultVariables))
      return keys.reduce(index, this.defaultVariables);
    }
    return null;
  }

  /**
   * Updates contact element
   * @param contactPerson 
   */
  updateContactElement(contactPerson, isChanged: boolean): void {
    this.offer.contact = contactPerson;
    // TODO: Überprüfen - bessere Lösung
    const temp1 = Object.assign({}, this.offer.contact.company);
    const temp2 = Object.assign({}, this.offer.contact);
    delete temp2.company;
    this.defaultVariables.Contact = Object.assign({}, temp1, temp2);
    this.updateContactAnnounced.next(contactPerson);
    if (isChanged) {
      this.unsavedChanges = true;
    } else {
      this.unsavedChanges = false;
    }
  }
  /**
   * Updates contact person element
   * @param contactPerson 
   */
  updateContactPersonElement(responsible): void {
    this.setValue('contactPersonName', responsible.contactPersonName);
    this.setValue('contactPersonPosition', responsible.contactPersonPosition);
    this.setValue('contactPersonPhone', responsible.contactPersonPhone);
    this.setValue('contactPersonMobile', responsible.contactPersonMobile);
    this.setValue('contactPersonFax', responsible.contactPersonFax);
    this.setValue('contactPersonEmail', responsible.contactPersonEmail);
    this.setValue('contactPersonAvatar', responsible.contactPersonAvatar);
    this.setValue('contactPersonSignature', responsible.contactPersonSignature);
    if (responsible.contactPersonName === '') {
      this.defaultVariables.Responsible = {
        fullname: `${this.defaultVariables.User.firstname} ${this.defaultVariables.User.lastname}`,
        position: this.defaultVariables.User.position,
        avatar: this.defaultVariables.User.avatar,
        signature: this.defaultVariables.User.signature,
      };
    } else {
      this.defaultVariables.Responsible = {
        fullname: responsible.contactPersonName,
        position: responsible.contactPersonPosition,
        avatar: responsible.contactPersonAvatar,
        signature: responsible.contactPersonSignature,
      };
    }
    //this.updateContactPersonAnnounced.next();
    this.unsavedChanges = true;
  }
  /**
   * Updates date element
   * @param offerDesc 
   * @param offerNumber 
   * @param date 
   * @param validUntil 
   * @param nonBinding 
   */
  updateDateElement(offerDesc: any, offerNumber: any, date: any, validUntil: any, nonBinding: boolean): void {
    this.offer.offerDesc = offerDesc;
    this.offer.offerNumber = offerNumber;
    this.offer.date = date;
    const formatDate = moment(date).format('DD.MM.YYYY');
    this.defaultVariables.Offer.formatDate = formatDate;
    this.offer.formatDate = formatDate;
    this.offer.validUntil = validUntil;
    this.offer.nonBinding = nonBinding;
    this.unsavedChanges = true;
  }
  /**
   * Updates subject element
   * @param subject 
   */
  updateSubjectElement(subject: any): void {
    this.offer.subject = subject;
    this.unsavedChanges = true;
  }
  /**
   * Loads offer
   * @param offer 
   */
  async loadOffer(offer: Offer, unsaved: boolean, classicMode) {
    this.coverImageBase64 = '';
    this.coverImage = null;

    this.offer = new Offer();
    
    // https://gitlab.com/angebotsfabrik/webapp/-/merge_requests/826
    // without this if statement, the offer number displayed in the preview of the info document. 
    // It is not correct. But in the past it has side effects on bulk document generation.
    if (this.paramsAction === 'preview'){
      if (this.paramsType === 'info' || this.paramsType === 'offer') {
        if (offer.category) {
          this.paramsType = offer.category;
        }
      }
    }
    this.offer = offer;
    this.offer.date = new Date();
    await this.getCompany();
    let days = this.defaultVariables.Company.settings.validUntillDays;
    let result = new Date();
    result.setDate(result.getDate() + days);
    this.offer.validUntil = result;

    offer.price = 0;

    this.loadAgb()
    this.updateDocumentsList();

    await this.loadProducts(classicMode);

    let formatDate = moment(offer.date).format('DD.MM.YYYY');
    this.defaultVariables.Offer = offer;
    this.defaultVariables.Offer.formatDate = formatDate;

    this.defaultVariables.Responsible = {
      fullname: offer.contactPersonName,
      position: offer.contactPersonPosition,
      avatar: offer.contactPersonAvatar,
      signature: offer.contactPersonSignature,
    };

    this.updateContactElement(offer.contact ? offer.contact : new ContactPerson(), false)

    await this.blockEventService.load(offer);
    this.blockEventService.getAll().forEach(ev => {
      for (const block of offer.blocks) {
        if (block._id === ev.block) {
          block.fields.forEach(field => {
            if (field?.useMSG) {
              field.value = new Date(ev.start).toISOString();
            }
          })
        }
      }
    })
    this.updateOffer();

    this.unsavedChanges = unsaved;
  }
  /**
   * Sets value hidden to hide elements in contact person fields
   * @param key 
   * @param value 
   */
  setValue(key: string, value) {
    if (value === '' && key !== 'contactPersonName') {
      this.offer[key] = "hidden";
    } else {
      this.offer[key] = value;
    }
    if (key === 'contactPersonName') {
      this.offer[key] = value;
    }
    this.unsavedChanges = true;
  }
  /**
   * Updates offer
   */
  updateOffer() {
    this.offerAnnounced.next(this.offer);
  }
  /**
   * Gets offer
   * @returns offer 
   */
  getOffer(): Offer {
    return this.offer;
  }
  /**
   * Adds document to the offer
   * @param media 
   */
  addDocument(pdf: DataPdf) {
    if (!this.offer.documents) {
      this.offer.documents = [];
    }
    if (this.offer.documents.filter(item => item.file._id === pdf.file._id).length === 0) {
      this.offer.documents.push(pdf);
      this.unsavedChanges = true;
      this.updateDocumentsList();
    }
  }

  /**
   * Removes document from the offer
   * @param media 
   */
  removeDocument(pdf: DataPdf) {
    for (let i = this.offer.documents.length; i--;) {
      if (this.offer.documents[i] === pdf) {
        this.offer.documents.splice(i, 1);
      }
    }
    this.updateDocumentsList()
    this.unsavedChanges = true;
  }

  /**
   * Removes legacy document from the offer
   * @param media 
   */
  removeLegacyDocument(pdf: string) {
    for (let i = this.offer.documentsLegacy.length; i--;) {
      if (this.offer.documentsLegacy[i] === pdf) {
        this.offer.documentsLegacy.splice(i, 1);
      }
    }
    //this.updateDocumentsList()
    this.unsavedChanges = true;
  }
  /**
   * Adds or removes agb in the offer.
   * @param status 
   */
  addAgb(status: boolean) {
    this.offer.agb = status;
    status ? this.offer.agbFile = this.company.agb : this.offer.agbFile = undefined;
    this.offer.agb = status;
    this.updateDocumentsList();
    this.unsavedChanges = true;
  }
  /**
   * Loads status of the agb. By default agb is always active.
   */
  loadAgb(): void {
    // add agb wenn it is a new document and not info text
    if (typeof this.offer.agb === 'undefined' && this.paramsType != 'info') {
      this.addAgb(true);
      return
    }
    if (this.offer.agb && !this.offer.agbFile) {
      this.addAgb(true);
    }
  }
  /**
   * Sets offer number error
   * @param invalid 
   */
  setOfferNumberError(invalid: boolean) {
    this.offerNumberIsInvalid = invalid;
  }
  /**
   * Adds comments to offer
   * @param comment 
   */
  addCommentsToOffer(comment: string) {
    this.offer.comments = comment;
  }
  updateDocumentsList() {
    let list = '';
    if (this.offer.agb) {
      list = "AGB"
      if (this.offer.documents) {
        this.offer.documents.forEach((d) => {
          list = list + `, ${d.name}`;
        })
      }
    }
    if (!this.offer.agb && this.offer.documents && this.offer.documents.length > 0) {
      list = this.offer.documents[0].name;
      for (let i = 1; i < this.offer.documents.length; i++) {
        list = list + `, ${this.offer.documents[i].name}`;
      }
    }
    this.offer.documentsList = list;
    this.updateOffer()
  }

  async loadProducts(classicMode: boolean = false): Promise<void> {
    if (classicMode) {
      await this.loadClassic();
    } else {
      await this.loadNew();
    }
  }

  async loadNew(){
    const catCoverpage = {
      where: {
        categorie: 'cover',
      }
    }
    this.updateCatCoverpageAnnounced.next(await this.myProductService.getWithInjection(catCoverpage));

    const catForm = {
      where: {
        categorie: 'form',
      }
    }
    this.updateCatFormAnnounced.next(await this.myProductService.getWithInjection(catForm));

    const catOffer = {
      where: {
        categorie: 'offer',
      }
    }
    this.updateCatOfferAnnounced.next(await this.myProductService.getWithInjection(catOffer));

    const catWriteto = {
      where: {
        categorie: 'writeto',
      }
    }

    const products = await this.myProductService.getWithInjection(catWriteto);

    this.updateCatWritetoAnnounced.next(products);

  }

  async loadClassic(){
    const products = await this.myProductService.getWithInjection();
    this.updateCatWritetoAnnounced.next(products);
  }

  setCoverpageImage(file: File): void {
    this.coverImage = file;
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.coverImageBase64 = 'data:image/png;base64,' + (reader.result as string).split(',')[1];
      };
    }
  }

  removeCoverpageImage() {
    this.coverImage = null;
    this.coverImageBase64 = '';
    this.offer.coverImage = null;
  }

  public get hasCoverpageImage(): boolean {
    if (this.coverImage || this.coverImageBase64 || this.offer.coverImage) {
      return true;
    }
    return false;
  }

  get coverpageImagePath(): string {
    return this.offer.coverImage ? `${this.url}media/${this.offer.coverImage}` : '';
  }

  addCoverpagePdf(pdf: DataPdf) {
    this.offer.coverPage = pdf;
  }
  removeCoverpagePdf() {
    this.offer.coverPage = null;
    this.unsavedChanges = true;
  }

  public get hasCoverpagePdf(): boolean {
    return this.offer.coverPage ? true : false;
  }

  public isPreviewMode(): boolean {
    if (this.paramsAction === 'preview') {
      return true;
    } 
    return false;
  }

  setAlternativeContact(type: string) {
    this.offer.useAlternativeContact = type;
    this.updateOffer();
  }
}

