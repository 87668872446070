import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../service/auth.service';
import { environment } from '@app/../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  logo: any;
  /**
   * Creates an instance of header component.
   * @param authService 
   */
  constructor(private authService: AuthService) {}

  async ngOnInit(): Promise<void> {
    const user = await this.authService.getCurrentUser();
    const company = user.company;
    try {
      const imgId = company.branch.logo.toString();
      this.logo = this.getUrl(imgId);
    } catch (error) {
      this.logo = '../../../assets/images/af_logo_loader.png';
    }
  }

  getUrl(imgId: string) {
    return `${environment.api_url}media/${imgId}`;
  }
}
