import { Component } from '@angular/core';
import { DashboardService } from '../index/index.dashboard.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent {
  today: number = Date.now();

  constructor(
    public dashboardService: DashboardService,
    private router: Router,
  ) {}

  addPage(){
    this.dashboardService.addPage();
  }

  setActivePage(n: number){
    this.dashboardService.setActivePage(n);
  }

  removePage(){
    this.dashboardService.removePage();
  }

  navigateLeft(){
    this.dashboardService.navigateLeft();
  }

  navigateRight(){
    this.dashboardService.navigateRight();
  }
  
  getColor(index): string {
    if (index === this.dashboardService.activePageNumber) {
      return '#fd3e00';
    }
  }

  getDockState(): boolean {
    if (this.router.url.includes('app/index')) {
      return true;
    } else {
      return false;
    }
  }
}
