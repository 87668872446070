import { ApiResponse, ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { Company } from '@app/property/company';
import { AuthService } from '@app/service/auth.service';
import { User } from '@app/property/user';
import { NotificationService } from '@app/service/notification.service';

@Injectable()
export class CompanyService extends ApiService {
  constructor(
    protected override http: HttpClient,
    private authService: AuthService,
    private notificationService: NotificationService
  ) {
    super(http);
  }

  getById(id: string): Promise<Company> {
    return this.http
      .get<ApiResponse>(`${this.url}company/${id}`, this.options)
      .toPromise()
      .then((response) => {
        const company = response.data as Company;
        return Promise.resolve(company);
      });
  }

  getAll(): Promise<Company[]> {
    return this.http
      .get<ApiResponse>(`${this.url}company/`, this.options)
      .toPromise()
      .then((response) => {
        const company = response.data as Company[];
        return Promise.resolve(company);
      });
  }

  async getCurrent(): Promise<Company> {
    const user: User = await this.authService.getCurrentUser();
    return this.getById(user.company.name);
  }

  async deleteById(id: string): Promise<boolean> {
    return this.http
      .delete(`${this.url}company/${id}`, this.options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(true);
      });
  }

  async save(data): Promise<Company> {
    const formData: any = data;

    if (formData._id) {
      return this.http
        .put<ApiResponse>(`${this.url}company/${formData._id}`, formData, this.options)
        .toPromise()
        .then((response) => {
          this.notificationService.showNotification('Änderungen wurden gespeichert');
          return Promise.resolve(response.data as Company);
        })
        .catch((error) => {
          console.error(error);
          return Promise.reject(error);
        });
    }

    return this.http
      .post<ApiResponse>(`${this.url}company/`, formData, this.options)
      .toPromise()
      .then((response) => {
        //console.log('response: ', response);
        this.notificationService.showNotification('Unternehmen wurde angelegt');
        return Promise.resolve(response.data as Company);
      })
      .catch((error) => {
        console.log(error.error.errors);
        return Promise.reject(error.error.errors);
      });
  }

  async saveProducts(form: FormGroup): Promise<Company> {
    const formData: any = form.value;
    return this.http
      .put<ApiResponse>(`${this.url}company/${formData._id}/products`, formData, this.options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response.data as Company);
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  }

  async saveProductGroups(form: FormGroup): Promise<Company> {
    const formData: any = form.value;
    if (formData._id) {
      return this.http
        .put<ApiResponse>(`${this.url}company/${formData._id}/productgroups`, formData, this.options)
        .toPromise()
        .then((response) => {
          return Promise.resolve(response.data as Company);
        })
        .catch((error) => {
          console.error(error);
          return Promise.reject(error);
        });
    }
  }

  async duplicate(company: Company): Promise<Company> {
    return this.http
      .post<ApiResponse>(`${this.url}democompany/`, company, this.options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response.data as Company);
      })
      .catch((error) => {
        console.log(error.error.errors);
        return Promise.reject(error.error.errors);
      });
  }

  getCompanyUsers(): Promise<User[]> {
    return this.http
      .get<ApiResponse>(this.url + 'companyusers/', this.options)
      .toPromise()
      .then((response) => {
        const tmp = response.data as any[];
        tmp.forEach(data => {
          if (data.dashboard) {
            data.dashboard = JSON.parse(data.dashboard);
          }
        });
        return Promise.resolve(tmp as User[]);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  getOfferCompany(id: string): Promise<Company> {
    return this.http
      .get<ApiResponse>(`${this.url}offercompany/${id}`, this.options)
      .toPromise()
      .then((response) => {
        const company = response.data as Company;
        return Promise.resolve(company);
      });
  }

  migrateCompany(): Promise<any> {
    return this.http
      // .get<ApiResponse>(`${this.url}/contacts/migrate/conacts-in-company`, this.options)
      .put<ApiResponse>(`${this.url}/company/migrate/update-fields`, this.options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response.data);
      });

  }
}
