import { ApiResponse, ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Blockgroup } from '../property/blockgroup';
import { NotificationService } from '@app/service/notification.service';

@Injectable()
export class BlockgroupService extends ApiService {
    constructor(
        protected override http: HttpClient,
        private notificationService: NotificationService
    ) {
            super(http);
    }

    getAll(): Promise<Blockgroup[]> {
        return this.http.get<ApiResponse>(this.url + 'blockgroups/', this.options)
            .toPromise()
            .then(response => {
                return Promise.resolve((response.data as Blockgroup[]));
            });
    }

    getById(id: string): Promise<Blockgroup> {
        return this.http.get<ApiResponse>(this.url + 'blockgroups/' + id, this.options)
            .toPromise()
            .then(response => {
                return Promise.resolve((response.data as Blockgroup));
            });
    }

    save(blockgroup: Blockgroup): Promise<Blockgroup> {

        const formData: FormData = new FormData();

        formData.append('name', blockgroup.name);
        formData.append('icon', blockgroup.icon);

        if (blockgroup._id) {
            return this.http.put<ApiResponse>(this.url + 'blockgroups/' + (blockgroup._id), blockgroup, this.options)
                .toPromise()
                .then(response => {
                    this.notificationService.showNotification('Änderungen wurden gespeichert');
                    return Promise.resolve((response.data as Blockgroup));
                });
        } else {
            return this.http.post<ApiResponse>(this.url + 'blockgroups/', blockgroup, this.options)
                .toPromise()
                .then(response => {
                    this.notificationService.showNotification('Bausteingruppe wurde angelegt');
                    return Promise.resolve((response.data as Blockgroup));
                });
        }
    }

    deleteById(id: string) {

        return this.http.delete(this.url + 'blockgroups/' + id, this.options)
            .toPromise()
            .then(response => {
                this.notificationService.showNotification('Bausteingruppe wurde gelöscht');
                return Promise.resolve(true);
            });
    }


}
