/**
 * The file contents settings for development enviroment.
 * The list of which env maps can be found in .angular-cli.json.
 */

export const environment = {
  production: true,
  api_url: 'https://dev.mandanten-manager.de/api/',
  testsystem: false,
  redirectUri: 'https://dev.mandanten-manager.de/',
  contentSystem: false,
  systemLabel: 'DEV',
};
