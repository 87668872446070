import { ApiResponse, ApiService } from './api.service';
import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { User } from '../property/user';
import { HttpClient } from '@angular/common/http';
import { Company } from '@app/property/company';
import { NotificationService } from '@app/service/notification.service';
import { UserService } from './user.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends ApiService implements CanActivate {
  public currentUser: User;
  public language: BehaviorSubject<string> = new BehaviorSubject<string>('de-stb');

  constructor(
    protected override http: HttpClient,
    private router: Router,
    private notificationService: NotificationService,
  ) {
    super(http);

    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
  }

  public setLanguage(language: string) {
    this.language.next(language);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (state.url === '/login') {
      if (this.currentUser) this.router.navigate(['/']);
      return true;
    }
    if (!this.currentUser) {
      if (state.url !== '/login') {
        sessionStorage.setItem('redirectTo', state.url);
        this.router.navigate(['/login']);
        return;
      }
    }
    if (this.currentUser.roles.every((role) => role.allows.superAdmin === false)) {
      if (state.url.indexOf('/admin/') !== -1) {
        this.router.navigate(['/']);
      }
      // return true;
    }
    if (this.currentUser.roles.every((role) => role.allows.companyAdmin === false)) {
      if (state.url.indexOf('/company-admin/') !== -1) {
        this.router.navigate(['/']);
      }
      // return true;
    }

    if (this.currentUser) {
      if (state.url === '/login') {
        this.router.navigate(['/']);
      }
      return true;
    }
  }

  login(username: string, password: string): Promise<void | User> {
    return this.http
      .post<ApiResponse>(`${this.url}authenticate/login`, { username, password }, this.options)
      .toPromise()
      .then((response) => {
        const user = response.data as User;
        if (user && user.token) {
          this.currentUser = user;
          sessionStorage.setItem('currentUser', JSON.stringify(user));
        }
        return Promise.resolve(user);
      })
      .catch((msg) => {
        this.notificationService.showErrorNotification(msg.error?.errors[0]);
        Promise.reject(msg);
      });
  }
  /**
   * Logouts from the web app
   */
  logout() {
    sessionStorage.removeItem('currentUser');
    this.currentUser = null;
  }

  getCurrentUser(): Promise<User> {
    // return Promise.resolve(this.currentUser);
    return this.http
      .get(`${this.url}authenticate/getCurrentUser`, this.options)
      .toPromise()
      .then((response) => {
        const tmp = response as any;
        if (tmp.dashboard) {
          tmp.dashboard = JSON.parse(tmp.dashboard);
        }
        const user = tmp as User;

        if (user?.company?.branch?.language) this.setLanguage(user?.company?.branch?.language);

        if (user?._id) {
          this.updateUser(user);
        }
        // if (user.email) {
        //   // AuthService.authenticated = true;
        // } if (user.role.name === 'Administrator') {
        //   // AuthService.isAdmin = true;
        // }
        return Promise.resolve(user);
      });
  }

  updateCompany(company: Company) {
    if (this.currentUser.company._id === company._id) {
      const user = JSON.parse(sessionStorage.getItem('currentUser'));
      user.company = company;
      this.updateUser(user);
    }
  }

  updateUser(user: User) {
    if (this.currentUser._id === user._id) {
      this.currentUser = Object.assign({}, this.currentUser, user);
      sessionStorage.setItem('currentUser', JSON.stringify(this.currentUser));
    }
  }
}
