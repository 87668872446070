import { ApiResponse, ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { User } from '../property/user';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from '@app/service/notification.service';
import { AppConfigService } from '@app/service/app-config.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuestService extends ApiService implements CanActivate {
  public currentUser: User;

  constructor(
    protected override http: HttpClient, 
    private router: Router,
    private notificationService: NotificationService, 
    private appConfigService: AppConfigService,
  ) {
    super(http);
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const isSafeMode = await this.appConfigService.getSafeModeStatus();
    if (isSafeMode) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }

  

}
