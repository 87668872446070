import { Injectable } from '@angular/core';
import { Block } from '@app/property/block';
import { MyEvent } from '@app/property/my-event';
import { Offer } from '@app/property/offer';
import { User } from '@app/property/user';
import { AuthGraphService } from '@app/service/auth-graph.service';
import { CalendarGraphService } from '@app/service/calendar-graph.service';
import { MyEventService } from '@app/service/my-event.service';
@Injectable({
  providedIn: 'root'
})
export class BlockEventService {

  private myEvents: MyEvent[];

  constructor(
    private authGraphService: AuthGraphService,
    private calendarGraphService: CalendarGraphService,
    private myEventService: MyEventService,
  ) {
    this.myEvents = []
   }

  public add(myEvent: MyEvent): void {
    this.myEvents.push(myEvent);
  }

  public remove (block: Block): void {
    this.myEvents = this.myEvents.filter ( e => e.block != block._id);
  }

  public getAll(): MyEvent[]{
    return this.myEvents;
  }

  public getByBlockId(block: Block): MyEvent{
    return this.myEvents.find ( e => e.block.toString() === block._id.toString());
  }
  
  public async load(offer: Offer): Promise<MyEvent[]>{
    if (offer?.myEvents && offer.myEvents.length > 0){
      this.myEvents = offer.myEvents;
    } else {
      this.myEvents = [];
      return this.myEvents;
    }
    
    if (!this.authenticated){
      await this.authGraphService.signIn();
    } else if (this.authenticated){
      for (const myEvent of this.myEvents) {
        if (myEvent.msgId){
          const graphEvent = await this.calendarGraphService.getEvent(myEvent.msgId,'UTC');
          if ( graphEvent && graphEvent.changeKey != myEvent.changeKey){
            myEvent.start = new Date (graphEvent.start.dateTime);
          }
        }
      }
    }
    return this.myEvents;
  }

  public async prepareEvents(offer: Offer, user: User): Promise<MyEvent[]>{
    const timeZone = this.authGraphService.user?.timeZone ?? 'UTC';
    for (const ev of this.myEvents){
      let changed = false;
      if (!ev.msgId){
        // console.log(ev.newEvent);
        const graphEvent = ev.newEvent.getGraphEvent(timeZone);
        // graphEvent.isOnlineMeeting = true;
        // graphEvent.location = { displayName: 'tralala city' };
        const newGraphEvent = await this.calendarGraphService.addEventToCalendar(graphEvent);
        // console.log(newGraphEvent);
        ev.changeKey = newGraphEvent.changeKey;
        ev.msgId = newGraphEvent.id;
        delete ev.newEvent;
        changed = true;
      } 
      if (offer._id && !ev.document){
        ev.document = offer._id;
        changed = true;
      }
      if (!ev.owner){
        ev.owner = user._id;
      }
      if (changed){
        const myEvent = await this.myEventService.save(ev);
        ev._id = myEvent._id;
      }
    }

    return this.myEvents;
  }

  // Is a user logged in?
  get authenticated(): boolean {
    //console.log(this.authGraphService.authenticated);
    return this.authGraphService.authenticated;
  }
}
