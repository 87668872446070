// 
export const OAuthSettings = {
    appId: 'a42617ce-5d3d-471f-af01-b40fcdf41ecb',
    //redirectUri: 'http://localhost:4200',
    scopes: [
      "user.read",
      "mailboxsettings.read",
      "calendars.readwrite",
      "contacts.read",
    ]
  };