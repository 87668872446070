import { ApiResponse, ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { User } from '../property/user';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from './notification.service';
import { AuthService } from '@app/service/auth.service';

@Injectable()
export class ProfileService extends ApiService {
  constructor(
    protected override http: HttpClient,
    private notificationService: NotificationService,
    private authService: AuthService,
  ) {
    super(http);
  }

  getUserInformation(id: string): Promise<User> {
    return this.http.get<ApiResponse>(this.url + 'users/userInformation/' + id, this.options)
      .toPromise()
      .then((response) => {
        const user = response.data as User;
        return Promise.resolve(user);
      });
  }

  updateUserInformation(value: any): Promise<User> {
    return this.http.put<ApiResponse>(`${this.url}users/userInformation/${value._id}`, value, this.options)
      .toPromise()
      .then((response) => {
        const user = response.data as User;
        this.authService.updateUser(response.data as User);
        this.notificationService.showNotification('Änderungen wurden gespeichert');
        return Promise.resolve(user);
      }, (error) => {
        return Promise.reject(error);
      });
  }

  savePassword(value: any): Promise<boolean> {
    return this.http.put<ApiResponse>(
      `${this.url}users/changePassword/${value._id}`,
      { password: value.password_new, old_password: value.password_old },
      this.options)
      .toPromise()
      .then((response) => {
        const user = response.data;
        this.notificationService.showNotification('Passwort wurde geändert');
        return Promise.resolve(true);
      }, (error) => {
        console.log(error.error.errors);
        return Promise.reject(error.error.errors);
      });
  }
}
