import { GridsterItem } from 'angular-gridster2';
/**
 * Panel type
 */
export enum Panel_Type {
    UNUSED,
    OfferOverview,
    OfferNews,
    OfferNew,
    MyOffers,
    MeAsContactPerson,
    MeAsAssignedContactPerson,
    OffersSentToSigning,
    FormsSentToSigning,
    // DueMonthOffers,
    // DueThisWeekOffers,
    // DueNextWeekOffers,
    DueOffers,
    OffersInDraft,
    ContactNew,
    BlocksOverview,
    OfferList,
    Clock,
    MyEvents,
    GraphContacts,
    MeAsDeputyOffers,
}
/**
 * Panel Class
 */
export class Panel {
  /**
   * Creates an instance of panel.
   * @param gridItem 
   * @param panelType 
   */
  constructor(
    public gridItem: GridsterItem,
    public panelType: Panel_Type,
  ) {}
}
