import { Injectable } from "@angular/core";
import { CalendarNativeDateFormatter, DateFormatterParams } from "angular-calendar";

@Injectable()
export class CustomDateFormatter extends CalendarNativeDateFormatter {
  // http://plnkr.co/edit/INtKoU2Tony08Soqy8x7?p=preview&preview
  public override dayViewHour({ date, locale }: DateFormatterParams): string {
    return new Intl.DateTimeFormat('de', {
      hour: 'numeric',
      minute: 'numeric'
    }).format(date);
  }

}