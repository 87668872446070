import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { Injectable } from '@angular/core';

@Injectable()
export class MatPaginatorIntlDe extends MatPaginatorIntl {
    override itemsPerPageLabel = 'Elemente pro Seite';
    override nextPageLabel = 'Nächste Seite';
    override previousPageLabel = 'Vorherige Seite';
    override firstPageLabel = 'Erste Seite';
    override lastPageLabel = 'Letzte Seite';
    override getRangeLabel = function (page, pageSize, length) {
        if (length === 0 || pageSize === 0) {
            return '0 von ' + length;
        }
        length = Math.max(length, 0);
        const /** @type {?} */ startIndex = page * pageSize;
        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const /** @type {?} */ endIndex = startIndex < length ?
            Math.min(startIndex + pageSize, length) :
            startIndex + pageSize;
        return startIndex + 1 + ' - ' + endIndex + ' von ' + length;
    };
}
