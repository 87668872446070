import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class NotificationService {

  constructor(private toastr: ToastrService) { }

  showNotification(notificationMessage: string, notificationTitle: string = ''){
    this.toastr.info(notificationMessage, notificationTitle, {
      closeButton: true,
      tapToDismiss: true,
      newestOnTop: true,
      timeOut: 5000,
      extendedTimeOut: 1000,
      disableTimeOut: false,
      easeTime: 300,
    })
  }

  showWarningNotification(notificationMessage: string, notificationTitle: string = '', timeOut: number = 5000){
    this.toastr.warning(notificationMessage, notificationTitle, {
      closeButton: true,
      tapToDismiss: true,
      newestOnTop: true,
      timeOut: timeOut,
      extendedTimeOut: 1000,
      disableTimeOut: false,
      easeTime: 300,
    })
  }

  showSuccessNotification(notificationMessage: string, notificationTitle: string = ''){
    this.toastr.success(notificationMessage, notificationTitle, {
      closeButton: true,
      tapToDismiss: true,
      newestOnTop: true,
      timeOut: 5000,
      extendedTimeOut: 1000,
      disableTimeOut: false,
      easeTime: 300,
      positionClass: 'toast-top-center',
    })
  }

  showErrorNotification(notificationMessage: string, notificationTitle: string = ''){
    this.toastr.error(notificationMessage, notificationTitle, {
      closeButton: true,
      tapToDismiss: true,
      newestOnTop: true,
      timeOut: 5000,
      extendedTimeOut: 1000,
      disableTimeOut: false,
      easeTime: 300,
      positionClass: 'toast-top-center',
    })
  }

  showAlert(notificationMessage: string = '', notificationTitle: string = ''){
    this.toastr.warning(notificationMessage, notificationTitle, {
      tapToDismiss: true,
      closeButton: true,
      newestOnTop: true,
      progressBar: true,
      progressAnimation: 'decreasing',
      disableTimeOut: false,
      timeOut: 7000,
      extendedTimeOut: 1000,
      easeTime: 300,
      positionClass: 'toast-top-full-width',

    })
  }


}
