import {Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-app',
  templateUrl: './app.layout.default.component.html',
  styleUrls: ['./app.layout.default.component.scss'],
})

export class AppLayoutDefaultComponent implements OnInit {
  //@ViewChild('cookieLaw', {static: true})
  //cookieLawEl: any;

  //cookieLawSeen: boolean;

  ngOnInit() {
    //this.cookieLawSeen = this.cookieLawEl.cookieLawSeen;
  }
}
