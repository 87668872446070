import { NgModule,  } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';

import { IPublicClientApplication,
  PublicClientApplication,
  BrowserCacheLocation } from '@azure/msal-browser';
import { MsalModule,
  MsalService,
  MSAL_INSTANCE } from '@azure/msal-angular';
import { OAuthSettings } from '../config/oauth';
import { environment } from '@app/../environments/environment';

import { MatButtonModule } from '@angular/material/button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatMenuModule } from '@angular/material/menu';
import { MatLegacyPaginatorModule as MatPaginatorModule, MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatToolbarModule } from '@angular/material/toolbar';
// import { MatTooltipModule } from '@angular/material/tooltip';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { HeaderLoginComponent } from '@app/components/header/header.login.component';
import { FooterComponent } from './components/footer/footer.component';
import { MenuComponent } from './components/header/menu.component';
import { AppRoutingModule } from './app.routes';
import { AuthService } from './service/auth.service';
import { OfferService } from './service/offer.service';
import { FormService } from './service/form.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { AppLayoutDefaultComponent } from './app.layout.default.component';
import { AppLayoutGuestComponent } from './app.layout.guest.component';
import { OfferDetailService } from './components/offer/services/offer.detail.service';
import { AppLayoutEmptyComponent } from './app.layout.empty.component';
import { ProfileService } from './service/profile.service';
import { DatePipe } from '@angular/common';
import { NotificationService } from './service/notification.service';
import { BlockService } from './service/block.service';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { BlockgroupService } from './service/blockgroup.service';
import { UserService } from './service/user.service';
import { TermsOfUseService } from './service/accepted-terms-of-use.service';
import { MediaService } from './service/media.service';
import { ByteFormatPipe } from './pipes/byteformat.pipe';
import { MatPaginatorIntlDe } from './i18n/matPaginatorIntlDe';
import { CanDeactivateGuard } from '@app/service/canDeactivate.guard.service';
import { BackgroundComponent } from '@app/components/background/background.compontent';
// import { CookieLawModule } from 'angular2-cookie-law';
import { PasswordResetService } from '@app/service/passwordReset.service';
import { ForgotPasswordComponent } from '@app/components/forgot-password/forgotpassword.component';
import { ChangePasswordComponent } from '@app/components/forgot-password/changepassword/changepassword.component';
import { CompanyService } from '@app/service/company.service';
import { JwtInterceptor } from '@app/helper/jwt.interceptor';
import { ContactFormService } from '@app/service/contactform.service';
import { BranchService } from '@app/service/branch.service';
import { ProductGroupService } from '@app/service/productgroup.service';
import { MyProductGroupService } from '@app/service/myproductgroup.service';
import { ProductService } from '@app/service/product.service';
import { MyProductService } from '@app/service/myproduct.service';
import { QrCodeService } from '@app/service/qrcode.service';
import { AdvantageService } from '@app/service/advantage.service';
import { RoleService } from '@app/service/role.service';
import { OfferTemplateService } from '@app/service/offertemplate.service';
import { HelpVideoService } from '@app/service/helpvideo.service';
import { ToastrModule } from 'ngx-toastr';
import { MatDividerModule } from '@angular/material/divider';
import { TermsOfUseDialogComponent } from '@app/shared/terms-of-use-dialog/terms-of-use-dialog.component'
import { LogService } from './service/log.service';
import { MtxNativeDatetimeModule } from '@ng-matero/extensions/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

let msalInstance: IPublicClientApplication | undefined = undefined;

export function MSALInstanceFactory(): IPublicClientApplication {
  msalInstance = msalInstance ?? new PublicClientApplication({
    auth: {
      clientId: OAuthSettings.appId,
      redirectUri: environment.redirectUri,
      postLogoutRedirectUri: environment.redirectUri
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    }
  });

  return msalInstance;
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    AppLayoutDefaultComponent,
    AppLayoutEmptyComponent,
    AppLayoutGuestComponent,
    HeaderComponent,
    HeaderLoginComponent,
    FooterComponent,
    MenuComponent,
    BackgroundComponent,
    ByteFormatPipe,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    TermsOfUseDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    MatButtonModule,
    MatCardModule,
    MatToolbarModule,
    MatInputModule,
    MatIconModule,
    MatMenuModule,
    MatMenuModule,
    MatSnackBarModule,
    // MatTooltipModule,
    MatTableModule,
    MatDialogModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    // CookieLawModule,
    MsalModule,
    MatDividerModule,
    ToastrModule.forRoot({
      maxOpened: 3,
      positionClass: 'toast-bottom-right',
    }), // ToastrModule added
    MtxNativeDatetimeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    })  ],
  providers: [
    AuthService,
    OfferService,
    FormService,
    BlockService,
    BlockgroupService,
    CompanyService,
    NotificationService,
    OfferDetailService,
    ProfileService,
    DatePipe,
    UserService,
    TermsOfUseService,
    MediaService,
    ContactFormService,
    CanDeactivateGuard,
    PasswordResetService,
    BranchService,
    ProductGroupService,
    MyProductGroupService,
    ProductService,
    MyProductService,
    QrCodeService,
    AdvantageService,
    RoleService,
    OfferTemplateService,
    HelpVideoService,
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlDe },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    MsalService,
    LogService
  ],
  bootstrap: [
    AppComponent,
  ],
})

export class AppModule { }
