import { ApiResponse, ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { QrCode } from '@app/property/qrcode';

@Injectable()
export class QrCodeService extends ApiService {
  /**
   * Creates an instance of qrcode service.
   * @param http 
   * @param notificationService 
   */
  constructor(
    protected override http: HttpClient, 
    ) {
    super(http);
  }

  getById(id: string): Promise<QrCode> {
    return this.http
      .get<ApiResponse>(`${this.url}qrcode/${id}`, this.options)
      .toPromise()
      .then((response) => {
        const qrcode = response.data as QrCode;
        return Promise.resolve(qrcode);
      });
  }

  getAll(): Promise<QrCode[]> {
    return this.http
      .get<ApiResponse>(`${this.url}qrcode/`, this.options)
      .toPromise()
      .then((response) => {
        const qrcodes = response.data as QrCode[];        
        return Promise.resolve(qrcodes);
      });
  }

  async deleteById(id: string): Promise<boolean> {
    return this.http
      .delete(`${this.url}qrcode/${id}`, this.options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(true);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async save(formData: QrCode): Promise<QrCode> {
    
    if (formData._id) {
      return this.http
        .put<ApiResponse>(`${this.url}qrcode/${formData._id}`, formData, this.options)
        .toPromise()
        .then((response) => {
          return Promise.resolve(response.data as QrCode);
        })
        .catch((error) => {
          console.error(error);
          return Promise.reject(error);
        });
    }

    return this.http
      .post<ApiResponse>(`${this.url}qrcode/`, formData, this.options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response.data as QrCode);
      })
      .catch((error) => {
        console.log(error.error.errors);
        return Promise.reject(error.error.errors);
      });
  }
}
