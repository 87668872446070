import { ApiResponse, ApiService } from './api.service';

import { Injectable } from '@angular/core';
import { Block } from '../property/block';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from '@app/service/notification.service';
@Injectable({
  providedIn: 'root'
})

export class PasswordResetService extends ApiService {

  constructor(protected override http: HttpClient, private notificationService: NotificationService) {
    super(http);
  }

  async submitRequest(email: string): Promise<void> {
    if (!email) {
      return;
    }
    return this.http
      .post<ApiResponse>(
      this.url + 'resetpassword/',
      {
        email,
      },
      this.options,
      )
      .toPromise()
      .then(response => {});
  }

  async tokenValid(email: string, token: string): Promise<boolean> {
    if (!email || !token) {
      return Promise.resolve(false);
    }
    return this.http
      .post<boolean>(
        this.url + 'resetpassword/tokenvalid',
        {
          email: email,
          token: token,
        },
        this.options,
      )
      .toPromise()
      .then(response => {
        return Promise.resolve(response);
      });
  }

  submitPasswordChange(email: string, token: string, password: string): Promise<boolean> {
    return this.http.post<ApiResponse>(
      `${this.url}resetpassword/changepassword`,
      {
        email: email,
        token: token,
        password: password,
      },
      this.options,
    )
    .toPromise()
    .then(response => {
      return Promise.resolve(true);
    }, (error) => {
      //console.log(error.error.errors);
      return Promise.reject(error.error.errors);
    });
  }
}
