<button mat-icon-button tabindex="-1" type="button" class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon">close</mat-icon>
</button>
<h3>Video-Tutorials:</h3>
<div class="list">
    <div class="element" *ngFor="let item of helpVideos">
        <mat-icon class="inline-icon" (click)="openPlayer(item)">play_circle_outline</mat-icon>
        <span matTooltip="{{item?.description}}" matTooltipClass="tooltip"
            (click)="openPlayer(item)">{{item.name}}</span>
    </div>
</div>