import { ApiResponse, ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';

@Injectable()
export class FormService extends ApiService {
  constructor(
    protected override http: HttpClient,
  ) {
    super(http);
  }
  // TODO Deprecated
  // getForm(form): any {
  //   const headers = new Headers();
  //   headers.append('Accept', 'application/pdf');
  //   return this.http
  //     .get<ApiResponse>(`${this.url}forms/pdf/${form._id}`, this.options)
  //     .toPromise()
  //     .then((response) => {
  //       return Promise.resolve(response);
  //     });
  // }

  // getDownloadToken() {
  //   return this.http
  //     .get<ApiResponse>(`${this.url}forms/getFormToken`, this.options)
  //     .toPromise()
  //     .then((response) => {
  //       return Promise.resolve(response.data);
  //     });
  // }

  // getContactFromPdf(file){
  //   const fd = new FormData();
  //   fd.append('file', file);

  //   const options: object = {
  //     withCredentials: true,
  //     headers: new HttpHeaders({
  //       Accept: 'application/json',
  //       // 'Content-Type': 'application/json'
  //     }),
  //   };

  //   return this.http
  //     .post<ApiResponse>(`${this.url}forms/read`, fd, options)
  //     .toPromise()
  //     .then((response) => {
  //       return Promise.resolve(response);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       return Promise.resolve(false);
  //     });
  // }
}
