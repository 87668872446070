import { ApiService } from "./api.service";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Media } from "../property/media";
import { catchError, map } from 'rxjs/operators';
import { lastValueFrom } from "rxjs";

@Injectable()
export class MediaService extends ApiService {
  constructor(protected override http: HttpClient) {
    super(http);
  }

  upload(
    file,
    fileType: "" | "companyLogo" | "contactsUpload" = "",
    token: string = "",
    id: string = ""
  ): Promise<Media> {
    return new Promise((fulfill, reject) => {
      const fd = new FormData();
      fd.append("file", file);

      const options: object = {
        withCredentials: true,
        headers: new HttpHeaders({
          Accept: "application/json",
          // 'Content-Type': 'application/json'
        }),
      };

      let path = "";
      if (fileType === "") {
        path = "media/upload";
      } else if (fileType === "contactsUpload" && token && id) {
        path = `v2/media/upload/contactdata/${id}/${token}`;
      } else if (fileType === "companyLogo") {
        path = "media/uploadlogo";
      }

     
      this.http
        .post<Media>(this.url + path, fd, options)
        .toPromise()
        .then((response) => {
          fulfill(response as Media);
        })
        .catch((err) => {
          console.error("ERROR: ", err);
          reject();
        });
    });
  }

  async downloadMediaWithAuth(id: string, name): Promise<any> {
    const options: object = {
      withCredentials: true,
      headers: new HttpHeaders({
        // Accept: "application/json",
      }),
      responseType: 'blob',
    };
    let result;
    try {
      const response = this.http
        .get<any>(this.url + `v2/media/upload/with-auth/${id}`, options);
        result = await lastValueFrom(response);
    } catch (error) {
      console.log(error);
    }

    //  if the file is not found, try to download it without auth
    if (!result) { 
      try {
        const response = this.http
          .get<any>(this.url + `/media/${id}`, options);
          result = await lastValueFrom(response);
      } catch (error) {
        console.log(error);
        return undefined;
      }
    }

    const filename = name;
    const url = window.URL.createObjectURL(result);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', filename);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    return result;
  }

  async downloadMultipleMediaWithAuth(ids: string[], archiveName = 'archive'): Promise<any> {
    const options: object = {
      withCredentials: true,
      headers: new HttpHeaders({
        // Accept: "application/json",
      }),
      responseType: 'blob',
    };

    try {
      const response = this.http.post<any>(`${this.url}download-multiple-media/`, { ids }, options);
      const result = await lastValueFrom(response);

      const filename = `${archiveName}.zip`;
      const url = window.URL.createObjectURL(result);
      const a = document.createElement('a');
      a.setAttribute('hidden', '');
      a.setAttribute('href', url);
      a.setAttribute('download', filename);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      return result;

    } catch (error) {
      console.log(error);
    }
  }

  async deleteMedia(id: string){
    const options: object = {
      withCredentials: true,
      headers: new HttpHeaders({
        // Accept: "application/json",
      }),
    };
    let result;
    try {
      const response = this.http
        .delete<any>(this.url + `v2/media/upload/with-auth/${id}`, options);
        result = await lastValueFrom(response);
        return result.data
    } catch (error) {
      console.log(error);
    }
    // if the file is not found, try to delete it without auth
    if (!result) { 
      try {
        const response = this.http
          .delete<any>(this.url + `/media/${id}`, options);
          const result = await lastValueFrom(response);
          return result.data
      } catch (error) {
        console.log(error);
        return undefined;
      }
    }
  }
}
