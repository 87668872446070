import { ApiService } from '@app/service/api.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class ContactFormService extends ApiService {
  constructor(
    protected override http: HttpClient
  ) {
    super(http);
  }

  sendContactForm(data) {
    return this.http.post(`${this.url}form/contact`, data, this.options)
      .toPromise();
  }
}
