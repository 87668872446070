<mat-toolbar class="mat-toolbar-header">

    <a class="logo" *ngIf="logo" [routerLink]="['/']">
        <img [src]="logo" style="max-height: 45px;">
    </a>
    
    <nav fxHide.xs>
        <app-menu class="app-menu" fxLayout="row" fxLayoutAlign="center"></app-menu>
    </nav>

</mat-toolbar>
