import { ApiResponse, ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from '@app/service/notification.service';
import { MyEvent } from '@app/property/my-event'

@Injectable({
  providedIn: 'root'
})

export class MyEventService extends ApiService {

  /**
   * Creates an instance of product service.
   * @param http 
   * @param notificationService 
   */
  constructor(
    protected override http: HttpClient, 
    private notificationService: NotificationService,
    ) {
    super(http);
  }

  async save( event: MyEvent): Promise<MyEvent> {
    if (!event._id){
      return this.http
      .post<ApiResponse>(`${this.url}events/`, event, this.options)
      .toPromise()
      .then((response) => {
        //console.log('response: ', response);
        this.notificationService.showNotification('Termin wurde erstellt');
        return Promise.resolve(response.data as MyEvent);
      })
      .catch((error) => {
        console.log(error.error.errors);
        return Promise.reject(error.error.errors);
      });
    } else {
      return this.http
      .put<ApiResponse>(`${this.url}events/${event._id}`, event, this.options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response.data as MyEvent);
      })
      .catch((error) => {
        console.log(error.error.errors);
        return Promise.reject(error.error.errors);
      });
    }
   
  }
  getAll(options = {}): Promise<MyEvent[]> {
    let params = null;
    if (options) {
      params = Object
        .keys(options)
        .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(typeof options[k] === 'object' ? JSON.stringify(options[k]) : options[k])}`)
        .join('&');
    }

    return this.http
      .get<ApiResponse>(`${this.url}events/${params !== '' ? '?' + params : ''}`, this.options)
      .toPromise()
      .then((response) => {
        const myEvents = response.data as MyEvent[];
        return Promise.resolve(myEvents);
      });
  }
}
