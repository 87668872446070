import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'distinctProductFilter'
})
export class distinctProductFilterPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let resArr = [];
    let resStr = ''
    value.forEach(function (item) {
      if (item.product && !item.product.isHidden) {
        let i = resArr.findIndex(x => item.product.name == x.name);
        if (i <= -1) {
          resArr.push({ name: item.product.name });
          if (resStr === '') {
            resStr = `${item.product.name}`;
          } else {
            resStr = `${resStr}, ${item.product.name}`;
          }
        }
      }
    });
    return resStr;
  }

}
