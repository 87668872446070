import { Injectable } from '@angular/core';
import { Client } from '@microsoft/microsoft-graph-client';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';

import { AuthGraphService } from './auth-graph.service';

@Injectable({
  providedIn: 'root'
})
export class CalendarGraphService {

  private graphClient: Client;
  constructor(
    private authService: AuthGraphService,
    ) {

    // Initialize the Graph client
    this.graphClient = Client.init({
      authProvider: async (done) => {
        // Get the token from the auth service
        const token = await this.authService.getAccessToken()
          .catch((reason) => {
            done(reason, null);
          });

        if (token)
        {
          done(null, token);
        } else {
          done("Could not get an access token", null);
        }
      }
    });
  }

  async getCalendarView(start: string, end: string, timeZone: string): Promise<MicrosoftGraph.Event[] | undefined> {
    try {
      // GET /me/calendarview?startDateTime=''&endDateTime=''
      // &$select=subject,organizer,start,end
      // &$orderby=start/dateTime
      // &$top=50
      const result =  await this.graphClient
        .api('/me/calendarview')
        .header('Prefer', `outlook.timezone="${timeZone}"`)
        .query({
          startDateTime: start,
          endDateTime: end
        })
        .select('subject,body,organizer,attendees,start,end,changeKey')
        .orderby('start/dateTime')
        .top(50)
        .get();

        //console.log(result);
      return result.value;
    } catch (error) {
      console.log(error);
    }
    return undefined;
  }
  // https://docs.microsoft.com/en-us/graph/api/event-get?view=graph-rest-1.0&tabs=javascript
  async getEvent(id: string, timeZone: string): Promise<MicrosoftGraph.Event | undefined> {
    timeZone = 'UTC'
    try {
      const result =  await this.graphClient
        .api(`/me/events/${id}`)
        .header('Prefer', `outlook.timezone="${timeZone}"`)
        .select('subject,body,organizer,attendees,start,end,changeKey')
        .get();
      return result;
    } catch (error) {
      console.log(error);
    }
    return undefined;
  }

  // <AddEventSnippet>
  async addEventToCalendar(newEvent: MicrosoftGraph.Event): Promise<MicrosoftGraph.Event | undefined> {
    try {
      // POST /me/events
      const result = await this.graphClient
        .api('/me/events')
        .post(newEvent);
       
      return result;
    } catch (error) {
      throw Error(JSON.stringify(error, null, 2));
    }
  }
  // </AddEventSnippet>
  async updateEvent(id: string, updates: MicrosoftGraph.Event): Promise<MicrosoftGraph.Event | undefined> {
    try {
      // POST /me/events
      const result = await this.graphClient
        .api(`/me/events/${id}`)
        .update(updates);
       
      return result;
    } catch (error) {
      return undefined //throw Error(JSON.stringify(error, null, 2));
    }
  }
}