import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/service/auth.service';
import { environment } from '@app/../environments/environment';

@Component({
  selector: 'app-background',
  templateUrl: './background.component.html',
})
export class BackgroundComponent implements OnInit {
  bgimg: any;
  bgover: any = 'rgba(0,0,0,0.25)';
  random: any;
  /**
   * Creates an instance of background component.
   * @param authService
   */
  constructor(private authService: AuthService) {}
  /**
   * on init choose default picture. Check user's company branch. if there is special backround for specific
   * branch, then load that picture.
   */
  async ngOnInit() {
    const user = await this.authService.getCurrentUser();
    const company = user.company;
    try {
      const imgId = company.branch.background.toString();
      this.bgimg = this.getUrl(imgId);
    } catch (error) {
      this.bgimg = '/assets/images/af_background_content1.jpg';
    }
  }

  getUrl(imgId: string) {
    return `${environment.api_url}media/${imgId}`;
  }
}
