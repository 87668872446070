import { ApiResponse, ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { User } from '../property/user';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from '@app/service/notification.service';
import { AuthService } from '@app/service/auth.service';
import { AcceptedTermsOfUse } from '@app/property/acceepted-terms-of-use';

@Injectable()
export class TermsOfUseService extends ApiService {
  constructor(
    protected override http: HttpClient,
    private notificationService: NotificationService,
    private authService: AuthService,
  ) {
    super(http);
  }

  async checkIfAcceptedTermsOfUse(email: string): Promise<{ termsOfUse: string; accepted: boolean }> {
    if (!email) {
      return Promise.resolve({ termsOfUse: '', accepted: false });
    }
    return this.http
      .post<ApiResponse>(
        this.url + 'users/checktermsofuse',
        {
          email: email,
        },
        this.options,
      )
      .toPromise()
      .then((response) => {
        return Promise.resolve(response.data as { termsOfUse: string; accepted: boolean });
      });
  }

  // Accept terms of use for logged in user
  async acceptTermsOfUseUser(email: string): Promise<boolean> {
    if (!email) {
      return Promise.resolve(false);
    }
    return this.http
      .post<boolean>(
        this.url + 'users/accepttermsofuse',
        {
          email: email,
        },
        this.options,
      )
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      });
  }

  // Accept terms of use with token
  async acceptTermsOfUseWithToken(email: string, token: string): Promise<boolean> {
    if (!email || !token) {
      return Promise.resolve(false);
    }
    return this.http
      .post<boolean>(
        this.url + 'users/accepttou',
        {
          email: email,
          token: token,
        },
        this.options,
      )
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      });
  }

  async resetAcceptedTermsOfUse(branchId): Promise<boolean> {
    return this.http
      .post<boolean>(this.url + 'users/resettermsofuse', { branchId }, this.options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      });
  }

  getAllAcceptedByUser(userId: string): Promise<AcceptedTermsOfUse[]> {
    return this.http
      .get<ApiResponse>(this.url + 'users/acceptedtermsofuse/' + userId, this.options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response.data as AcceptedTermsOfUse[]);
      });
  }

}
