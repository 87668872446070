import { enableProdMode, isDevMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'codemirror/mode/htmlembedded/htmlembedded';
// import 'codemirror/mode/javascript/javascript';
// import 'codemirror/addon/lint/html-lint';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/addon/edit/closetag';
import 'codemirror/addon/selection/active-line';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import './vendor';

if (environment.production) {
  enableProdMode();
}

if (isDevMode()) {

}

platformBrowserDynamic().bootstrapModule(AppModule).then(ref => {
  // Ensure Angular destroys itself on hot reloads.
  if (window['ngRef']) {
    window['ngRef'].destroy();
  }
  window['ngRef'] = ref;

  // Otherwise, log the boot error
}).catch(err => console.error(err));
