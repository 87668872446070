<div fxLayout="row" fxLayoutAlign="center">
  <div fxFlex="100%">

    <div class="login-card">
      <mat-card fxLayout="column" fxLayoutAlign="center">

        <mat-card-title>Password zurücksetzen</mat-card-title>

        <mat-card-content>

          <form [formGroup]="passwordResetForm" (ngSubmit)="submit()" fxLayout="column wrap" fxLayoutAlign="end" *ngIf="!submitted">
            <mat-form-field fxFlex.xs="100%" fxFlex="100%">
              <input matInput name="email" placeholder="E-Mail-Adresse" #email formControlName="email">
            </mat-form-field>

            <p>Geben Sie hier die E-Mail-Adresse Ihres Benutzerkontos an.</p>

            <a color="primary" routerLink="/login" mat-raised-button>Zurück zum Login</a>

            <button mat-raised-button type="submit" title="Absenden" color="accent" [disabled]="!passwordResetForm.valid">Absenden</button>
          </form>

          <div *ngIf="submitted">
            <p>
              Wenn es ein Benutzerkonto mit dieser E-Mail-Adresse gibt, so werden wir Ihnen einen Link senden, über den Sie das Passwort
              zurücksetzen können.
            </p>
          </div>

        </mat-card-content>

      </mat-card>
    </div>
  </div>
</div>
