import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-popup-dialog',
  templateUrl: './popup-dialog.content.component.html',
  styleUrls: ['./popup-dialog.content.component.css']
})
export class DialogContentComponent implements OnInit {

  @Input() dialogTitle: string = ""
  @Input() message: string = "";
  @Input() yesOptionText: string = "";
  @Input() noOptionText: string = "";
  @Input() save: Function = () => {}
  @Input() close: Function = () => {}
  @Input() icon: string = "";
  
  constructor(public dialogRef: MatDialogRef<DialogContentComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void{
    this.dialogTitle = this.data.dialogTitle
    this.message = this.data.message
    this.yesOptionText = this.data.yesOptionText
    this.noOptionText = this.data.noOptionText
    this.save = this.data.save
    this.close = this.data.close
    this.icon = this.data.icon
  }

  onYesClick(): void {
    this.dialogRef.close(true);
    this.save();
  }

  onNoClick(): void {
    this.dialogRef.close(false);
    this.close()
  }
}