<header>
    <app-header></app-header>
</header>
<main>
    <router-outlet></router-outlet>
</main>
<footer>
    <!-- <cookie-law #cookieLaw (isSeen)="cookieLawSeen = $event">
        Um unsere Webseite für Sie optimal zu gestalten und fortlaufend verbessern zu können, verwenden wir Cookies.
        Durch die weitere Nutzung der Webseite stimmen Sie der Verwendung von Cookies zu.
        <a href="/app/datenschutz">Erfahren Sie mehr</a>
    </cookie-law> -->
    <app-footer></app-footer>
</footer>
<app-background></app-background>
