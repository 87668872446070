import { Component } from '@angular/core';
// import {OfferDetailService} from './components/offer/shared/offer.detail.service';

@Component({
    selector: 'app-app',
    templateUrl: './app.layout.empty.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppLayoutEmptyComponent {

    constructor(
        // private offerDetailService: OfferDetailService,
    ) {

    }

    saveOffer(offer): void {
        // this.offerDetailService.saveOffer();
    }
}


