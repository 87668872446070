import { Block } from './block';
import { ContactPerson } from '@app/property/contactperson';
import { MetaUser } from '@app/property/metauser';
import { DataPdf } from './datapdf';
import { Media } from './media';
import { MyEvent } from './my-event';
/**
 * Data structure for the offer.
 */
export class Offer {
  _id: string;
  schemaVersion?: number = 3; //v2
  createdAt: Date;
  updatedAt: Date;
  contact?: ContactPerson;
  useAlternativeContact?: string = 'main';
  contactId?: string;
  subject?: string;
  dateSend?: Date;
  dateAvailable?: Date;
  blocks?: Array<Block>;
  status?: string;
  date?: Date;
  formatDate?: string;
  validUntil?: Date;
  nonBinding?: boolean;
  offerDesc?: string;
  offerNumber?: number;
  agb: boolean;
  agbFile: Media;
  documents: Array<DataPdf>;
  documentsLegacy: Array<string>;
  documentsList: string;
  createUntil?: Date;
  price?: number;
  metaUser?: MetaUser;
  contactPersonName?: string;
  contactPersonPosition?: string;
  contactPersonPhone?: string;
  contactPersonFax?: string;
  contactPersonMobile?: string;
  contactPersonEmail?: string;
  contactPersonAvatar?: Media;
  contactPersonSignature?: Media;
  comments?: string;
  category?: string; // v2
  templateName?: string;
  templateDescription?: string;
  myEvents?: MyEvent[];
  coverPage: DataPdf; // v3
  coverImage: Media;
  toDelete?: boolean;
  previewMode?: string;
  sentOn: Date;
  metaPdfData?: any;
}

/** change log
 * v2 adds
 * - schemaVersion
 * - category
 * 
 */