import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { HelpVideo } from '@app/property/helpvideo';
import { YoutubePlayerDialogComponent } from '../../youtube-player-dialog/youtube-player-dialog.component';

@Component({
  selector: 'app-playlist-dialog',
  templateUrl: './playlist-dialog.component.html',
  styleUrls: ['./playlist-dialog.component.css']
})
export class PlaylistDialogComponent implements OnInit {

  helpVideos: HelpVideo[];

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    this.helpVideos = this.data.params;
  }

  ngOnInit(): void {
    
  }

  openPlayer(helpVideo: HelpVideo) {
    const dialogRef = this.dialog.open(YoutubePlayerDialogComponent, {
      data: {
        params: helpVideo,
      },
    });
  }

}
