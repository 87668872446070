<mat-toolbar class="mat-toolbar-footer">

    <a mat-button [routerLink]="['/']">
        <span style="font-weight: 100;">ANGEBOTSFABRIK © {{today | date:'yyyy'}}</span>
    </a>

    <ng-container *ngIf="getDockState()">
        <div class="fixed bottom-0 z-50 h-16 -translate-x-1/2 left-1/2 pt-[2px]">
            <div class="grid h-full max-w-lg grid-cols-8 mx-auto">
                <button mat-icon-button (click)="navigateLeft()"
                    class="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group">
                    <mat-icon>arrow_circle_left</mat-icon>
                </button>

                <button mat-icon-button (click)="setActivePage(-1)"
                    class="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group">
                    <mat-icon [ngStyle]="{color: getColor(-1)}">home</mat-icon>
                </button>

                <ng-container *ngFor="let item of dashboardService.getPages() let i = index;">
                    <button mat-icon-button (click)="setActivePage(i)"
                        class="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group">
                        <mat-icon [ngStyle]="{color: getColor(i)}">circle</mat-icon>
                    </button>
                </ng-container>

                <button mat-icon-button (click)="navigateRight()"
                    class="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group">
                    <mat-icon>arrow_circle_right</mat-icon>
                </button>

                <button mat-icon-button (click)="addPage()"
                    class="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group">
                    <mat-icon>add</mat-icon>
                </button>

                <app-popup-dialog-btn
                        [saveClick]="removePage.bind(this)"
                        dialogTitle="Löschen bestätigen" 
                        message="Soll die Seite wirklich gelöscht werden?"
                        yesOptionText="Ja"
                        noOptionText="Abbrechen"
                      >
                        <ng-container *ngIf="dashboardService.activePageNumber >= 0">
                            <button mat-icon-button
                                class="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </ng-container>
                </app-popup-dialog-btn>

                <!-- https://flowbite.com/docs/components/bottom-navigation/ -->
                <!-- <button data-tooltip-target="tooltip-bookmark" type="button" class="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group">
                    <svg class="w-6 h-6 mb-1 text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path d="M5 4a2 2 0 012-2h6a2 2 0 012 2v14l-5-2.5L5 18V4z"></path>
                    </svg>
                    <span class="sr-only">Bookmark</span>
                </button>
                <div id="tooltip-bookmark" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                    Bookmark
                    <div class="tooltip-arrow" data-popper-arrow></div>
                </div> -->

            </div>
        </div>
    </ng-container>

    <nav fxHide.xs>
        <ul role="navigation">
            <li>
                <a mat-button [routerLink]="['/app/support']" class="nav-link" title="Support">Support</a>
            </li>
            <li>
                <a mat-button [routerLink]="['/app/impressum']" class="nav-link" title="Impressum">Impressum</a>
            </li>
            <li>
                <a mat-button [routerLink]="['/app/datenschutz']" class="nav-link" title="Datenschutz">Datenschutz</a>
            </li>
        </ul>
    </nav>

</mat-toolbar>