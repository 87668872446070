import { ApiResponse, ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { AuthService } from '@app/service/auth.service';
import { Role } from '@app/property/role';
import { NotificationService } from '@app/service/notification.service';

@Injectable()
export class RoleService extends ApiService {
  constructor(
    protected override http: HttpClient,
    private notificationService: NotificationService,
    private authService: AuthService) {
    super(http);
  }

  getById(id: string): Promise<Role> {
    return this.http
      .get<ApiResponse>(`${this.url}role/${id}`, this.options)
      .toPromise()
      .then((response) => {
        const role = response.data as Role;
        return Promise.resolve(role);
      });
  }

  getAll(): Promise<Role[]> {
    return this.http
      .get<ApiResponse>(`${this.url}role/`, this.options)
      .toPromise()
      .then((response) => {
        const role = response.data as Role[];
        return Promise.resolve(role);
      });
  }

  getRolesWithLicenses(): Promise<Role[]> {
    return this.http
      .get<ApiResponse>(`${this.url}/company-users-roles/`, this.options)
      .toPromise()
      .then((response) => {
        const roles = response.data as Role[];
        return Promise.resolve(roles);
      });
  }

  async deleteById(id: string): Promise<boolean> {
    return this.http
      .delete(`${this.url}role/${id}`, this.options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(true);
      })
      .catch((error) => {
        this.notificationService.showNotification(error.error.errors);
        return Promise.reject(error);
      });
  }

  async save(form: FormGroup): Promise<Role> {
    const formData: any = form.value;

    if (formData._id) {
      return this.http
        .put<ApiResponse>(`${this.url}role/${formData._id}`, formData, this.options)
        .toPromise()
        .then((response) => {
          this.notificationService.showNotification('Änderungen wurden gespeichert');
          return Promise.resolve(response.data as Role);
        })
        .catch((error) => {
          console.error(error);
          return Promise.reject(error);
        });
    }

    return this.http
      .post<ApiResponse>(`${this.url}role/`, formData, this.options)
      .toPromise()
      .then((response) => {
        console.log('response: ', response);
        this.notificationService.showNotification('Neue Rolle wurde angelegt');
        return Promise.resolve(response.data as Role);
      })
      .catch((error) => {
        console.log(error.error.errors);
        return Promise.reject(error.error.errors);
      });
  }
}
