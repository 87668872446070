import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl } from '@angular/forms';
import { UserService } from '@app/service/user.service';
import { User } from '@app/property/user';
import { PasswordResetService } from '@app/service/passwordReset.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
})
export class ChangePasswordComponent implements OnInit {
  valid: Promise<boolean>;
  success: boolean = false;
  token: string;
  email: string;
  changePasswordForm: FormGroup;
  passwordFormErrors = [];
  showPassword = {
    password: false,
    confirmPassword: false
  };
  
  /**
   * Compare two passwords from input fields of the change password form.
   * @param ac 
   * @returns  
   */
  static matchPassword(ac: AbstractControl) {
    const password = ac.get('password').value; // to get value in input tag
    const confirmPassword = ac.get('confirmPassword').value; // to get value in input tag
    if (password !== confirmPassword) {
      ac.get('confirmPassword').setErrors({ MatchPassword: true });
    } else {
      return null;
    }
  }
  /**
   * Creates an instance of change password component.
   * @param activatedRoute 
   * @param formBuilder 
   * @param passwordResetService 
   */
  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private passwordResetService: PasswordResetService
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.email = params['email'];
      this.token = params['token'];

      this.changePasswordForm = this.createForm(formBuilder);

      this.valid = Promise.resolve(this.isEmailTokenPairValid(this.email, this.token));
    });
  }
  /**
   * Creates form for change password
   * @param formBuilder 
   * @returns form 
   */
  createForm(formBuilder: FormBuilder): FormGroup {
    return formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(100)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(100)]],
    }, {
      validator: ChangePasswordComponent.matchPassword,
    });
  }
  /**
   * Determines whether email token pair valid is
   * @param email 
   * @param token 
   * @returns email token pair valid 
   */
  async isEmailTokenPairValid(email: string, token: string): Promise<boolean> {
    if (!email || !token) {
      return Promise.resolve(false);
    }
    return await this.passwordResetService.tokenValid(email, token);
  }
  /**
   * Submits changed password.
   * @returns submit 
   */
  async submit(): Promise<void> {
    const password = this.changePasswordForm.value.password;
    this.passwordResetService.submitPasswordChange(this.email, this.token, password).then(()=>{
      this.passwordFormErrors = [];
      this.success = true;
    }).catch((error)=>{
      this.success = false;
      this.passwordFormErrors = error;
    });
  }
  /**
   * on init nothing happens here.
   */
  ngOnInit() {}

  showHidePassword(event, key){
    event.stopPropagation();
    this.showPassword[key] = !this.showPassword[key]    
  }
}
