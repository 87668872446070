<ul role="navigation">
  <li *ngIf="showAdminMenu">
    <div *ngIf="awake" style=" margin-top: 3px; padding-right: 10px;">
      <fa-icon  style="color: green; margin-top: 10px;" [icon]="faFaceSmile"></fa-icon>
    </div>
    <div *ngIf="idle" style=" margin-top: 3px; padding-right: 10px;">
      <fa-icon  style="margin-top: 10px;" [icon]="faFaceDizzy"></fa-icon>
    </div>
  </li>
  <li>
    <button matTooltip='Startseite' matTooltipClass="tooltip" class="nav-link-home" mat-icon-button [routerLink]="['/']">
      <mat-icon [ngStyle]="{color: getColor('index')}" aria-label="Startseite">home</mat-icon>
    </button>

  </li>
  <li>
    <button matTooltip='Einstellungen' matTooltipClass="tooltip" class="nav-link" *ngIf="showAdminMenu" mat-icon-button
      (click)="navigateToAdminMenu()">
      <mat-icon [ngStyle]="{color: getColor('/admin/')}">settings</mat-icon>
    </button>
  </li>
  <li>
    <mat-divider vertical></mat-divider>
  </li>
  <li>
    <button matTooltip='Adminbereich' matTooltipClass="tooltip" class="nav-link" *ngIf="showCompanyAdminMenu"
      mat-icon-button (click)="navigateToCompanyAdminMenu()">
      <fa-icon [ngStyle]="{color: getColor('company-admin')}" [icon]="faUserCog"></fa-icon>
    </button>
  </li>
  <li>
    <button matTooltip='Unternehmensdaten' matTooltipClass="tooltip" class="nav-link"
      *ngIf="allowEditCompanyData || allowMaintainStandardPrices || allowManageContent || allowManageProductsAndGroups"
      mat-icon-button (click)="navigateToMyComnpanyDetails()">
      <mat-icon [ngStyle]="{color: getColor('mycompany')}">admin_panel_settings</mat-icon>
    </button>
  </li>
  <li>
    <mat-divider vertical></mat-divider>
  </li>
  <li *ngIf="allowManageContacts">
    <button matTooltip='Schnellerfassung Interessenten' matTooltipClass="tooltip"
      class="nav-link" mat-icon-button [routerLink]="['/app/profile/quickcontact']">
      <mat-icon [ngStyle]="{color: getColor('quickcontact')}" aria-label="Startseite">contacts</mat-icon>
    </button>
  </li>
  <li *ngIf="allowManageContacts">
    <button mat-icon-button matTooltip='Adressbuch' matTooltipClass="tooltip" class="nav-link"
      [routerLink]="['/app/profile/adressbuch']">
      <mat-icon [ngStyle]="{color: getColor('adressbuch')}">folder_shared</mat-icon>
    </button>
  </li>
  <li>
    <mat-divider vertical></mat-divider>
  </li>
  <li *ngIf="allowCreateProposals || allowCreateInfoDocuments">
    <button mat-icon-button matTooltip='Dokumentübersicht' matTooltipClass="tooltip" class="nav-link"
      [routerLink]="['/app/docoverview/offerlist']">
      <mat-icon [ngStyle]="{color: getColor('docoverview')}">topic</mat-icon>
    </button>
  </li>
  <li *ngIf="allowCreateProposals || allowCreateProposalTemplates">
    <button mat-icon-button matTooltip='Angebot erstellen' matTooltipClass="tooltip" class="nav-link"
      [routerLink]="['/app/offer/detail/']">
      <fa-icon [icon]="faFileSignature"></fa-icon>
    </button>
  </li>
  <li *ngIf="allowCreateInfoDocuments || allowAreateInfoDocumentTemplates">
    <button mat-icon-button matTooltip='Dokument erstellen' matTooltipClass="tooltip" class="nav-link"
      [routerLink]="['/app/offer/info']">
      <fa-icon [icon]="faFileAlt"></fa-icon>
    </button>
  </li>
  <li *ngIf="isVideo">
    <button mat-icon-button matTooltip='Hilfe' matTooltipClass="tooltip" class="nav-link" (click)="openPlayer()">
      <mat-icon aria-label="Startseite">help</mat-icon>
    </button>
  </li>
  <li>
    <button  [matMenuTriggerFor]="menu" aria-label="Menu">
      <div class="flex items-center">
        <mat-icon *ngIf="!avatar" class="avatar-replacement">account_circle</mat-icon>
        <img *ngIf="avatar" class="avatar" [src]="url">
        <mat-icon>keyboard_arrow_down</mat-icon>
      </div>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item [routerLink]="['/app/profile/settings','profile']">
        <mat-icon style="color: black;">edit</mat-icon><span>Profil bearbeiten</span>
      </button>
      <button mat-menu-item [routerLink]="['/app/profile/settings','password']">
        <mat-icon style="color: black;">lock</mat-icon>
        <span>Passwort ändern</span>
      </button>
      <button mat-menu-item (click)="logout()">
        <mat-icon style="color: black;">exit_to_app</mat-icon>
        <span>Abmelden</span>
      </button>
    </mat-menu>
  </li>
</ul>