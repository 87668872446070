import { ApiResponse, ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { User } from '@app/property/user';
import { Observable } from 'rxjs';
import { Product } from '@app/property/product';
import { NotificationService } from '@app/service/notification.service';

@Injectable()
export class ProductService extends ApiService {
  /**
   * Creates an instance of product service.
   * @param http 
   * @param notificationService 
   */
  constructor(
    protected override http: HttpClient, 
    private notificationService: NotificationService,
    ) {
    super(http);
  }

  getById(id: string): Promise<Product> {
    return this.http
      .get<ApiResponse>(`${this.url}product/${id}`, this.options)
      .toPromise()
      .then((response) => {
        const product = response.data as Product;
        return Promise.resolve(product);
      });
  }

  getAll(): Promise<Product[]> {
    return this.http
      .get<ApiResponse>(`${this.url}product/`, this.options)
      .toPromise()
      .then((response) => {
        const company = response.data as Product[];
        return Promise.resolve(company);
      });
  }

  async deleteById(id: string): Promise<boolean> {
    return this.http
      .delete(`${this.url}product/${id}`, this.options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(true);
      })
      .catch((error) => {
        this.notificationService.showNotification(error.error.errors);
        return Promise.reject(error);
      });
  }

  async save(formData: Product): Promise<Product> {
    if (formData._id) {
      return this.http
        .put<ApiResponse>(`${this.url}product/${formData._id}`, formData, this.options)
        .toPromise()
        .then((response) => {
          this.notificationService.showNotification('Änderungen wurden gespeichert');
          return Promise.resolve(response.data as Product);
        })
        .catch((error) => {
          console.error(error);
          return Promise.reject(error);
        });
    }

    return this.http
      .post<ApiResponse>(`${this.url}product/`, formData, this.options)
      .toPromise()
      .then((response) => {
        //console.log('response: ', response);
        this.notificationService.showNotification('Produkt wurde angelegt');
        return Promise.resolve(response.data as Product);
      })
      .catch((error) => {
        console.log(error.error.errors);
        return Promise.reject(error.error.errors);
      });
  }
}
