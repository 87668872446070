import { ApiResponse, ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { Block } from '../property/block';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NotificationService } from '@app/service/notification.service';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class BlockService extends ApiService {
  constructor(
    protected override http: HttpClient,
    private notificationService: NotificationService) {
    super(http);
  }
/**
 * Gets all blocks with params
 * @param [params] 
 * @returns all 
 */
getAll(params: HttpParams = new HttpParams()): Promise<ApiResponse> {
    return this.http.get<ApiResponse>(`${this.url}blocks/`, { params })
      .toPromise()
      .then((response) => {
        return Promise.resolve((response));
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
/**
 * Gets all blocks wothout params
 * @returns all blocks 
 */
getAllBlocks(): Promise<Block[]> {
    return this.http.get<ApiResponse>(`${this.url}allblocks/`, this.options)
      .toPromise()
      .then((response) => {
        return Promise.resolve((response.data as Block[]));
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async getById(id: string): Promise<Block> {
    let result;
    try {
      const response = this.http.get<ApiResponse>(`${this.url}blocks/${id}`, this.options);
      result = await lastValueFrom(response);
    } catch (error) {
      return undefined;
    }
      if (!result) { return undefined; }
      return result.data as Block;
  }

  save(block: Block): Promise<Block> {

    const formData: FormData = new FormData();

    formData.append('name', block.name);
    formData.append('internalName', block.internalName);
    formData.append('template', block.template);
    formData.append('description', block.description);

    if (block._id) {
      return this.http.put<ApiResponse>(`${this.url}blocks/${block._id}`, block, this.options)
        .toPromise()
        .then((response) => {
          this.notificationService.showNotification('Baustein wurde geändert');
          return Promise.resolve((response.data as Block));
        })
        .catch((error) => {
          console.error(error);
          return Promise.reject(error);
        });
    }
    return this.http.post<ApiResponse>(`${this.url}blocks/`, block, this.options)
      .toPromise()
      .then((response) => {
        this.notificationService.showNotification('Baustein wurde angelegt');
        return Promise.resolve((response.data as Block));
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  }

  saveWithoutNotifications(block: Block): Promise<Block> {
    if (block._id) {
      return this.http.put<ApiResponse>(`${this.url}blocks/${block._id}`, block, this.options)
        .toPromise()
        .then((response) => {
          return Promise.resolve((response.data as Block));
        })
        .catch((error) => {
          console.error(error);
          return Promise.reject(error);
        });
    }
  }

  deleteById(id: string) {
    return this.http.delete(`${this.url}blocks/${id}`, this.options)
      .toPromise()
      .then((response) => {
        this.notificationService.showNotification('Baustein wurde gelöscht');
        return Promise.resolve(true);
      });
  }

}
