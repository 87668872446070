import { ApiResponse, ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { Branch } from '@app/property/branch';
import { AuthService } from '@app/service/auth.service';
import { User } from '@app/property/user';
import { Observable } from 'rxjs';
import { ProductGroup } from '@app/property/productgroup';
import {NotificationService} from '@app/service/notification.service';

@Injectable()
export class BranchService extends ApiService {
  constructor(
    protected override http: HttpClient, 
    private authService: AuthService, 
    private notificationService: NotificationService
  ) {
    super(http);
  }

  getById(id: string): Promise<Branch> {
    return this.http
      .get<ApiResponse>(`${this.url}branch/${id}`, this.options)
      .toPromise()
      .then((response) => {
        const branch = response.data as Branch;
        return Promise.resolve(branch);
      });
  }

  getAll(): Promise<Branch[]> {
    return this.http
      .get<ApiResponse>(`${this.url}branch/`, this.options)
      .toPromise()
      .then((response) => {
        const branch = response.data as Branch[];
        return Promise.resolve(branch);
      });
  }

  async deleteById(id: string): Promise<boolean> {
    return this.http
      .delete(`${this.url}branch/${id}`, this.options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(true);
      });
  }

  async save(formData: Branch): Promise<Branch> {
    if (formData._id) {
      return this.http
        .put<ApiResponse>(`${this.url}branch/${formData._id}`, formData, this.options)
        .toPromise()
        .then((response) => {
          this.notificationService.showNotification('Änderungen wurden gespeichert');
          return Promise.resolve(response.data as Branch);
        })
        .catch((error) => {
          console.error(error);
          return Promise.reject(error);
        });
    }

    return this.http
      .post<ApiResponse>(`${this.url}branch/`, formData, this.options)
      .toPromise()
      .then((response) => {
        console.log('response: ', response);
        this.notificationService.showNotification('Branche wurde angelegt');
        return Promise.resolve(response.data as Branch);
      })
      .catch((error) => {
        console.log(error.error.errors);
        return Promise.reject(error.error.errors);
      });
  }

  getProductGroup(): Promise<ProductGroup[]> {
    return this.http
      .get<ApiResponse>(`${this.url}branch/productgroup`, this.options)
      .toPromise()
      .then((response) => {
        const productGroup = response.data as ProductGroup[];
        return Promise.resolve(productGroup);
      }).catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });;
  }

}
