<div>
	<h2 mat-dialog-title>{{dialogTitle}}</h2>
	<div mat-dialog-content>
		<ng-container *ngIf="icon; else noIcon">
			<div style="display: flex; justify-content: flex-start; align-items: center;"><mat-icon style="margin: 10px;" aria-label="Type">{{icon}}</mat-icon> {{message}}</div>
		</ng-container>
		<ng-template #noIcon>
			{{message}}
		</ng-template>
	</div>
	<div mat-dialog-actions>
		<button *ngIf="noOptionText" mat-flat-button (click)="onNoClick()" data-cy="popup-no-btt">{{noOptionText}}</button>&nbsp;
		<button *ngIf="yesOptionText" mat-flat-button color="accent" (click)="onYesClick()" class="right" data-cy="popup-yes-btt">{{yesOptionText}}</button>
	</div>
</div>