import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { UserService } from '@app/service/user.service';
import { User } from '@app/property/user';
import { PasswordResetService } from '@app/service/passwordReset.service';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {

  passwordResetForm: FormGroup;
  submitted = false;
  /**
   * Creates an instance of forgot password component.
   * @param formBuilder 
   * @param passwordResetService 
   */
  constructor(private formBuilder: FormBuilder, private passwordResetService: PasswordResetService) {
    this.passwordResetForm = this.createForm(formBuilder);
  }
  /**
   * on init nothing happens here
   */
  ngOnInit() {

  }

  /**
   * Creates passwordResetForm
   * @param formBuilder
   */
  createForm(formBuilder: FormBuilder): FormGroup {
    return formBuilder.group(
      {
        email: new FormControl('', [
          Validators.required,
          Validators.email,
        ]),
      },
    );
  }

  /**
   * Retrieves email from form and calls api
   */
  async submit(): Promise<void> {
    const form: FormGroup = this.passwordResetForm;
    const email: string = form.value['email'];

    this.passwordResetService.submitRequest(email);
    this.submitted = true;
  }

}
