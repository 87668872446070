import { Blockfield } from './blockfield';
import { Size } from './size';
import { environment } from '@app/../environments/environment';
import { Hashable } from '@app/property/Hashable';
// import { Branch } from './branch';
// import { ProductGroup } from './productgroup';
import { Product } from './product';
import { Advantage } from './advantage';
import { MetaUser } from './metauser';
import { DesignVariants } from './designvariants';

/**
 * Data structure for the block element.
 */
export class Block implements Hashable {
  static chachedSize?: { height: number, width: number };

  _id: string;
  name: string;
  internalName?: string;
  createdAt: Date;
  updatedAt: Date;
  /*
   Sehr geehrter {{gender}} {{lastname}},

   ihr Unternehmen {{company}} ist klassse!
   */
  icon?: string;
  template: string;
  description?: string;
  size?: Size;
  fields: Blockfield[];
  order?: number;
  pageNumber?: number;
  pageBreak?: boolean;
  avoidBreakInside?: boolean;
  invalid?: boolean = false;
  useCase?: string;
  status?: string;
  // types: cover, write, offer
  categorie?: string;
  // branch?: string;
  // productGroup?: string;
  product?: Product;
  advantages?: Advantage[];
  metaUser: MetaUser;
  designVariants?: DesignVariants;
  editor?: string;
  last?: boolean = false;
  links?: any;
  autoUpdate?: boolean = false;
  owner?: string;
  copyFrom?: string;
  isTemplate?: boolean = false;
  
/**
 * Forms block from JSON block
 * @param block 
 * @returns block 
 */
static fromJSON(block: any): Block {
    const tmp = new Block();
    tmp._id = block._id;
    tmp.name = block.name;
    tmp.internalName = block.internalName;
    tmp.icon = block.icon;
    tmp.template = block.template;
    tmp.description = block.description;
    tmp.size = block.size;
    tmp.fields = block.fields;
    tmp.order = block.order;
    tmp.status = block.status;
    tmp.useCase = block.useCase;
    // tmp.branch = block.branch;
    // tmp.productGroup = block.productGroup;
    tmp.product = block.product;
    tmp.categorie = block.categorie;
    tmp.advantages = block.advantages;
    tmp.pageBreak = block.pageBreak || false;
    tmp.avoidBreakInside = block.avoidBreakInside || false;
    tmp.pageNumber = block.pageNumber || 0;
    tmp.metaUser = block.metaUser;
    tmp.designVariants = block.designVariants;
    tmp.editor = block.editor;
    tmp.last = block.last || false;
    tmp.links = block.links;
    tmp.autoUpdate = block.autoUpdate || false;
    tmp.owner = block.owner;
    tmp.copyFrom = block.copyFrom;
    tmp.isTemplate = block.isTemplate || false;
    tmp.updatedAt = block.updatedAt;
    return tmp;
  }
/**
 * todo is it used somewhere? 
 */
hash(): string {
    return JSON.stringify({
      _id: this._id,
      order: this.order,
    });
  }
/**
 * Set page break
 * @returns true if page break 
 */
// getPageBreak(): boolean {
//     return this.pageBreak && this.pageBreak === true;
//   }
/**
 * Renders template
 * @returns template 
 */
renderTemplate(): Promise<String> {
    return new Promise((fulfill, reject) => {
      const regex = new RegExp(/{{(?!({|}))[a-zA-Z0-9_]+(?!({))}}/, 'g');
      let template = (' ' + this.template).slice(1);

      const expressions = [];
      let match;

      while ((match = regex.exec(template)) !== null) {
        expressions.push(match[0].replace('{{', '').replace('}}', ''));
      }
      expressions.map((expr) => {
        return this.fields.map((field) => {
          if (field.name !== expr) {
            return;
          }
          if (field.type === 'input' || field.type === 'textarea' || field.type === 'dropdown' || field.type === 'bulletPoint') {
            if (field.value){
              template = template.replace(new RegExp(`{{${expr}}}`, 'g'), `${field.value}`);
            } else {
              template = template.replace(new RegExp(`{{${expr}}}`, 'g'), `<span class="text-color-red">${field.placeholder}</span>`);
            }            
            return;
          }
          if (field.type === 'image' && field.image) {
            if (field.image.base64 && field.image.base64.trim() !== '') {
              template = template.replace(
                new RegExp(`{{${expr}}}`),
                '<img src="data:image/png;base64,' + field.image.base64 + '"/>',
              );
            } else if (field.image && field.image.filename) {
              template = template.replace(
                new RegExp('{{' + expr + '}}'),
                '<img src="' + environment.api_url + 'media/' + field.image._id + '"/>',
              );
              // TODO Hack to get images from test server
              // template = template.replace(
              //   new RegExp('{{' + expr + '}}'),
              //   '<img src="' + 'https://test.mandanten-manager.de/api/' + 'media/' + field.image._id + '"/>',
              // );
            }
            return;
          }
          if (field.type === 'qrcode') {            
            if (field.placeholder){
              template = template.replace(new RegExp(`{{${expr}}}`, 'g'), `<span class="text-color-red">${field.placeholder}</span>`);
            } else {
              template = template.replace(new RegExp(`{{${expr}}}`, 'g'), `<span class="text-color-red">[Qr-Code]</span>`);
            }
            return;
          }
          if (field.type === 'signature') {                   
            if (field.image && field.image.filename) {
              template = template.replace(
                new RegExp('{{' + expr + '}}'),
                '<div class="signature-image-container"><img src="' + environment.api_url + 'media/' + field.image._id + '"/></div>',
                 // TODO Hack to get images from test server
                // new RegExp('{{' + expr + '}}'),
                // '<div class="signature-image-container"><img src="' +'https://test.mandanten-manager.de/api/' + 'media/' + field.image._id + '"/></div>',
              );
            } else if (field.placeholder){
              template = template.replace(new RegExp(`{{${expr}}}`, 'g'), `<span class="text-color-red">${field.placeholder}</span>`);
            } else {
              template = template.replace(new RegExp(`{{${expr}}}`, 'g'), `<span class="text-color-red">[Unterschrift]</span>`);
            }
            return;
          }
          if (field.type === 'custom_date') {            
            if (field.placeholder){
              template = template.replace(new RegExp(`{{${expr}}}`, 'g'), `<span class="text-color-red">${field.placeholder}</span>`);
            } else {
              template = template.replace(new RegExp(`{{${expr}}}`, 'g'), `<span class="text-color-red">[Aktuelles Datum]</span>`);
            }
            return;
          }
          console.error(`Skipping field ${expr} due to an invalid field-type`);
          return;
        });
      });
      fulfill(template);
    });
  }

  isFilledOut(): boolean {
    return this.fields
      .map((f) => {
        if (f.type === 'qrcode') {
          return f.qrCodeData && f.qrCodeData.trim() !== '';
        }
        if (f.type === 'signature') {
          return f.signatureData && f.signatureData.trim() !== '';
        }
        if (f.type === 'image') {
          return f.image && f.image.filename && f.image.filename.trim() !== '';
        }
        if (f.inputType && f.inputType === 'price') {
          if (f.isResource && (!f.feeFrequency || !f.resourceName)) return false;
          return !!f.value;
        }
        if (f.inputType && f.inputType === 'date') {
          return !!f.value;
        }
        return f.value && typeof f.value === 'string' && f.value.trim() !== '';
      })
      .reduce((a, b) => a && b, true);
  }
}
