import { Injectable } from '@angular/core';
import { Panel, Panel_Type } from '@app/property/panel';
import { AuthService } from '@app/service/auth.service';
import { User } from '@app/property/user';
import { HttpClient } from '@angular/common/http';
import { ApiResponse, ApiService } from '@app/service/api.service';
import { UserService } from '@app/service/user.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardService extends ApiService {

  MAXIMUM_PAGES = 3;
  // dashboard act as home page and always available
  dashboard: Panel[] = null;
  // This are extra pages
  pages: [Panel[]];

  public activePageNumber = -1;

  user: User;
  /**
   * Creates an instance of dashboard service.
   * @param authService 
   * @param http 
   * @param userService 
   */
  constructor(
    private authService: AuthService,
    http: HttpClient,
    private userService: UserService,
  ) {
    super(http);

    /// DEBUG
    // window.exportDashboard = () => this.getItems();
    // window.importDashboard = (panels: Panel[]) => this.setItems(panels);
    // window.clearDashboard = this.clear;
  }
  /**
   * Gets user specific dashboard
   * @returns  
   */
  async getDashboard() {
    this.dashboard = null;
    this.pages = null;

    this.user = await this.authService.getCurrentUser();
    if (this.user.dashboard) {
      this.dashboard = this.user.dashboard;
      this.pages = this.user.dashboardPages;
    } else {
      this.clear();
    }
    return this.dashboard;
  }
  /**
   * Gets empty panel
   * @param x 
   * @param y 
   * @returns empty panel 
   */
  getEmptyPanel(x: number, y: number): Panel {
    return new Panel(
      {
        cols: 8,
        rows: 8,
        y: x,
        x: y,
      },
      Panel_Type.UNUSED,
    );
  }
  /**
   * Clears dashboard service
   */
  clear(): void {
    this.dashboard = new Array<Panel>();
  }
  /**
   * Adds item to the dashboard panel.
   * @param type 
   * @returns true if item 
   */
  public addItem(type: Panel_Type): boolean {
    let panel: Panel;
    panel = this.getNextUnusedField()
    if (panel !== null) {
      panel.panelType = type;
      //this.save();
      return true;
    }
    return false;
  }
  /**
   * Gets next unused field
   * @returns next unused field 
   */
  getNextUnusedField(): Panel {
    const page = this.getActivePage();
    page.forEach((panel) => {
      if (!panel) {
        panel = this.getEmptyPanel(0, 0);
      }
      if (panel && panel.panelType === Panel_Type.UNUSED) {
        return panel;
      }
    });
    page.push(this.getEmptyPanel(0, 0));
    return page[page.length - 1];
  }
  /**
   * Saves user's dashboard to backend. 
   * @returns save 
   */
  async save(): Promise<void> {

    const update = {
      dashboard: this.dashboard,
      dashboardPages: this.pages || [],
    }

    return this.http
      .put<ApiResponse>(`${this.url}me`, update, this.options)
      .toPromise()
      .then((response) => {
        this.authService.updateUser(response.data['user'] as User);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  /**
   * Removes item from the dashbord panel.
   * @param item 
   */
  public removeItem(item) {
    const page = this.getActivePage();
    page.splice(page.indexOf(item), 1);
    this.save();
  }

  getActivePage() {
    if (this.activePageNumber === -1) {
      return this.dashboard;
    }
    return this.pages[this.activePageNumber];
  }

  public setActivePage(index: number) {
    if (index < -1 || index >= this.pages?.length) {
      return;
    }
    if (index === -1) {
      this.activePageNumber = -1;
      return;
    }
    this.activePageNumber = index;
  }

  public getPages() {
    return this.pages;
  }

  public addPage() {
    if (this.pages && this.pages.length >= this.MAXIMUM_PAGES) {
      return;
    }
    const emptyPanel = this.getEmptyPanel(0, 0);
    if (!this.pages) {
      this.pages = [[emptyPanel]];
    } else {
      this.pages.push([emptyPanel]);
    }
    this.setActivePage(this.pages.length - 1);
    this.save();
  } 
  
  removePage() {
    if (!this.pages || this.pages.length <= 0) {
      return;
    }
    this.pages.splice(this.activePageNumber, 1);
    this.setActivePage(this.pages.length - 1);
    this.save();
  }

  navigateLeft() {
    if (this.activePageNumber === -1) {
      return;
    }
    if (this.activePageNumber === 0) {
      this.setActivePage(-1);
      return;
    }
    this.setActivePage(this.activePageNumber - 1);
  }

  navigateRight() {
    if (this.activePageNumber === -1) {
      this.setActivePage(0);
      return;
    }
    if (this.activePageNumber === this.pages.length - 1) {
      return;
    }
    this.setActivePage(this.activePageNumber + 1);
  }
}
