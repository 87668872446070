import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

export interface ApiResponse {
  data: Object;
  total: number;
  meta: Object;
}

export class ApiService {

  protected UNAUTHORIZED = 401;
  protected FORBIDDEN = 403;
  protected NOT_FOUND = 404;

  protected url: string = environment.api_url;

  protected options: object = {
    withCredentials: true,
    headers: new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
  };
  /**
   * Creates an instance of api service.
   * @param http 
   */
  constructor(protected http: HttpClient) {
  }
  /**
   * Handles error
   * @param error 
   * @param [data] 
   * @returns error 
   */
  protected handleError(error: any, data?: any): Promise<any> {
    // error['parent'] = ApiService;

    if (
      error.status === this.UNAUTHORIZED ||
      error.status === this.FORBIDDEN ||
      error.status === this.NOT_FOUND
    ) {
      // this.errorService.setError('Error occurred' || error);
    }
    // this.errorService.setError('Error occurred' || error);
    return Promise.reject(error.message || error);
  }
}
