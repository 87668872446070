import { Injectable } from "@angular/core";
import { BehaviorSubject, fromEvent, } from "rxjs";

/**
 * Alternative https://moribvndvs.github.io/ng2-idle/quickstart
 * 
*/

@Injectable({
    providedIn: 'root',
})
export class IdleService {

    public idle$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public wake$: BehaviorSubject<boolean> = new BehaviorSubject(true);

    isIdle = false;
    private idleAfterSeconds = 60;
    private countDown;

    constructor() {
        // Setup events
        fromEvent(document, 'mousemove').subscribe(() => this.onInteraction());
        fromEvent(document, 'touchstart').subscribe(() => this.onInteraction());
        fromEvent(document, 'keydown').subscribe(() => this.onInteraction());
    }

    onInteraction() {
        // Is idle and interacting, emit Wake
        if (this.isIdle) {
            this.isIdle = false;
            this.wake$.next(true);
            this.idle$.next(false);
        }

        // User interaction, reset start-idle-timer
        clearTimeout(this.countDown);
        this.countDown = setTimeout(() => {
            // Countdown done without interaction - emit Idle
            this.isIdle = true;
            this.idle$.next(true);
            this.wake$.next(false);
        }, this.idleAfterSeconds * 1_000)
    }

}