import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@app/service/auth.service';
import { Router } from '@angular/router';
import { environment } from '@app/../environments/environment';
import { YoutubePlayerDialogComponent} from '@app/shared/youtube-player-dialog/youtube-player-dialog.component';
import { HelpVideo } from '@app/property/helpvideo';
import { HelpVideoService } from '@app/service/helpvideo.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PlaylistDialogComponent } from '@app/shared/help-videos/playlist-dialog/playlist-dialog.component';
import { faUserCog } from '@fortawesome/free-solid-svg-icons';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { faFileSignature } from '@fortawesome/free-solid-svg-icons';
import { faFaceSmile } from '@fortawesome/free-solid-svg-icons';
import { faFaceDizzy } from '@fortawesome/free-solid-svg-icons';
import { IdleService } from '@app/service/idle.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})

export class MenuComponent implements OnInit, OnDestroy {

  isSuperAdmin = false;
  showAdminMenu = false;
  showCompanyAdminMenu = false;

  allowCreateProposals = false;
  allowCreateProposalTemplates = false;

  allowCreateInfoDocuments: boolean = false;
  allowAreateInfoDocumentTemplates: boolean = false;

  allowEditCompanySettings = false;
  allowEditCompanyData = false;
  allowManageContent = false
  allowMaintainStandardPrices = false;
  allowManageContacts = false;
  allowManageProductsAndGroups = false;

  avatar;
  url: string;

  helpVideos: HelpVideo[];

  faUserCog = faUserCog;
  faFileAlt = faFileAlt;
  faFileSignature = faFileSignature;
  faFaceSmile = faFaceSmile;
  faFaceDizzy = faFaceDizzy;
  
  idle$: any;
  wake$: any;
  idle: boolean = false;
  awake: boolean = true;

  /**
   * Creates an instance of menu component.
   * @param authService 
   * @param router 
   */
  constructor(
    private authService: AuthService, 
    private router: Router,
    private helpVideoService: HelpVideoService,
    public dialog: MatDialog,
    private idleService: IdleService,
    ) {
      this.helpVideos = [];
     }
  /**
   * on init checks status of the user. Administrator has accecs for additional menu.
   */
  async ngOnInit(): Promise<void> {
    const user = await this.authService.getCurrentUser();
    if (user.avatar) {
      this.avatar = user.avatar._id;
      this.url = environment.api_url + 'media/' + this.avatar;
    }
    this.allowCreateProposals = user.roles.some(role => role.allows.createProposals);
    this.allowCreateProposalTemplates = user.roles.some(role => role.allows.createProposalTemplates);

    this.allowCreateInfoDocuments = user.roles.some(role => role.allows.createInfoDocuments);
    this.allowAreateInfoDocumentTemplates = user.roles.some(role => role.allows.createInfoDocumentTemplates);

    this.showAdminMenu = user.roles.some(role => role.allows.superAdmin);
    this.showCompanyAdminMenu = user.roles.some(role => role.allows.companyAdmin);
    this.allowEditCompanySettings = user.roles.some(role => role.allows.manageCompany);
    this.allowManageContent = user.roles.some(role => role.allows.manageContent);
    this.allowEditCompanyData = user.roles.some(role => role.allows.manageCompany);
    this.allowMaintainStandardPrices = user.roles.some(role => role.allows.maintainStandardPrices);
    this.allowManageContacts = user.roles.some(role => role.allows.manageContacts)
    this.isSuperAdmin = user.roles.some(role => role.allows.superAdmin)
    this.allowManageProductsAndGroups = user.roles.some(role => role.allows.manageProductsAndGroups);

    const where = {
      where: {
        position: 'indexmenu',
        show: 'true',
      }
    }
    this.helpVideoService.getByPosition(where).then(helpVideos =>{
      this.helpVideos = helpVideos;
    })

    this.idle$ =  this.idleService.idle$.subscribe(s => this.idle = s);
    this.wake$ = this.idleService.wake$.subscribe(s => this.awake = s);
  }

  ngOnDestroy(): void {
    this.idle$.unsubscribe();
    this.wake$.unsubscribe();
  }

  /**
   * Logouts from the system and navigate to login screen.
   */
  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  navigateToAdminMenu():void{
    if (this.isSuperAdmin){
      this.router.navigate(['/admin/block/list']);
      return;
    }    
  }

  navigateToCompanyAdminMenu():void{
   // if (this.isSuperAdmin){
      this.router.navigate(['/company-admin/user/list']);
      return;
    //}    
  }

  navigateToMyComnpanyDetails():void{
    if (this.allowEditCompanyData){
      this.router.navigate(['/app/mycompany/info/list']);
      return;
    }
    if (this.allowManageContent){
      this.router.navigate(['/app/mycompany/files/list']);
      return;
    }
    if (this.allowManageProductsAndGroups){
      this.router.navigate(['/app/mycompany/product/list']);
      return;
    }
    if (this.allowMaintainStandardPrices){
      this.router.navigate(['/app/mycompany/pricetable/detail']);
      return;
    }
  }

  openPlayer() {
    if (this.helpVideos.length === 1) {
      const dialogRef = this.dialog.open(YoutubePlayerDialogComponent, {
        data: {
          params: this.helpVideos[0],
        },
      });
    } else if (this.helpVideos.length > 1) {
      const dialogRef = this.dialog.open(PlaylistDialogComponent, {
        data: {
          params: this.helpVideos,
        },
      });
    }
  }

  get isVideo(): boolean {
    if (this.helpVideos.length > 0) return true;
    return false;
  }
  getColor(url: string): string {
    if (this.router.url.includes(url)) {
      return '#fd3e00';
    }
  }
}
