import { ApiResponse, ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { User } from '../property/user';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from '@app/service/notification.service';
import { AuthService } from '@app/service/auth.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService extends ApiService {
  constructor(
    protected override http: HttpClient,
    private notificationService: NotificationService,
    private authService: AuthService,
  ) {
    super(http);
  }

  async save(user: User): Promise<User> {
    if (user._id) {
      await this.log(user._id, 'update', user);
      return this.http
        .put<ApiResponse>(`${this.url}users/${user._id}`, user, this.options)
        .toPromise()
        .then((response) => {
          this.notificationService.showNotification('Änderungen wurden gespeichert');
          this.authService.updateUser(response.data as User);
          return Promise.resolve(response.data as User);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    } else {
      return this.http
        .post<ApiResponse>(this.url + 'users/', user, this.options)
        .toPromise()
        .then((response) => {
          this.notificationService.showNotification(
            `Der Benutzer wurde angelegt und eine E-Mail an ${user.email} versendet.`,
          );
          this.log(response.data['user']['_id'], 'create');
          return Promise.resolve(response.data as User);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    }
  }

  getById(id: String): Promise<User> {
    return this.http
      .get<ApiResponse>(this.url + 'users/' + id, this.options)
      .toPromise()
      .then((response) => {
        const tmp = response.data as any;
        if (tmp.dashboard) {
          tmp.dashboard = JSON.parse(tmp.dashboard);
        }
        return Promise.resolve(tmp as User);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getAll(): Promise<User[]> {
    return this.http
      .get<ApiResponse>(this.url + 'users/', this.options)
      .toPromise()
      .then((response) => {
        const tmp = response.data as any[];
        tmp.forEach((data) => {
          if (data.dashboard) {
            data.dashboard = JSON.parse(data.dashboard);
          }
        });
        return Promise.resolve(tmp as User[]);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  deleteById(id: string) {
    return this.http
      .delete(this.url + 'users/' + id, this.options)
      .toPromise()
      .then((response: any) => {
        if (response.data.msg == 'isResponsible') {
          this.notificationService.showWarningNotification('Benutzer kann nicht gelöscht werden');
          return Promise.resolve(true);
        }
        this.notificationService.showNotification('Benutzer wurde gelöscht');
        this.log(id, 'delete');
        return Promise.resolve(true);
      });
  }

  sendLoginInfo(user: User): Promise<Boolean> {
    const { firstname, lastname, email } = user;
    return this.http
      .post<ApiResponse>(this.url + 'users/sendlogininfo/', { firstname, lastname, email }, this.options)
      .toPromise()
      .then((response) => {
        this.notificationService.showNotification(
          `Der Link zum Zurücksetzen des Benutzers wurde per E-Mai an ${user.email} versendet.`,
        );
        return Promise.resolve(true);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getLog(userid) {
    return this.http
      .get<ApiResponse>(`${this.url}userlog/${userid}`, this.options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response.data);
      });
  }

  async restoreVersion(versionId, userId) {
    await this.log(userId, 'restore', null, versionId);

    const reqBody = {
      versionId,
      userId,
    };

    return this.http
      .post<ApiResponse>(`${this.url}userrestore/`, reqBody, this.options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        console.log(error.error.errors);
        return Promise.reject(error.error.errors);
      });
  }

  async log(userId, logtype, newUser = null, versionId = null) {
    const currentUser = await this.authService.getCurrentUser();

    const reqBody = {
      logtype,
      currentuser: currentUser._id,
      loguserid: userId,
      newUser,
      versionId,
    };

    return this.http
      .post<ApiResponse>(`${this.url}userlog/`, reqBody, this.options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        console.log(error.error.errors);
        return Promise.reject(error.error.errors);
      });
  }
}
