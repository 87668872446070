import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppLayoutGuestComponent } from './app.layout.guest.component';
import { AppLayoutDefaultComponent } from './app.layout.default.component';
import { AuthService } from './service/auth.service';
import { AuthGuestService } from './service/auth-guest.service';
import { AppLayoutEmptyComponent } from './app.layout.empty.component';
import { ForgotPasswordComponent } from '@app/components/forgot-password/forgotpassword.component';
import { ChangePasswordComponent } from '@app/components/forgot-password/changepassword/changepassword.component';

export const routes: Routes = [
  {
    path: 'app',
    canActivate: [AuthService],
    children: [
      {
        path: 'offer',
        component: AppLayoutEmptyComponent,
        children: [
          {
            path: '',
            loadChildren: () => import('./components/offer/editor/offer-editor.module').then(m => m.OfferEditorModule),
            data: { preload: false },
          },
        ],
      },
      {
        path: '',
        component: AppLayoutDefaultComponent,
        children: [
          {
            path: 'index',
            loadChildren: () => import('./components/index/index.module').then(m => m.IndexModule),
            data: { preload: true, imageUrl: '/assets/images/af_background_content3.jpg' },
          },
          {
            path: 'profile',
            loadChildren: () => import('./components/profile/profile.module').then(m => m.ProfileModule),
            data: { preload: false },
          },
          {
            path: 'mycompany',
            loadChildren: () => import('./components/mycompany/mycompany.module').then(m => m.MyCompanyModule),
            data: { preload: false },
          },
          {
            path: 'docoverview',
            loadChildren: () => import('./components/offer/list/offer-list.module').then(m => m.OfferListModule),
            data: { preload: false },
          },

          // Footer - links

          {
            path: 'impressum',
            loadChildren: () => import('./components/imprint/imprint.module').then(m => m.ImprintModule),
            data: { preload: false },
          },
          {
            path: 'support',
            loadChildren: () => import('./components/contact/contact.module').then(m => m.ContactModule),
            data: { preload: false },
          },
          {
            path: 'datenschutz',
            loadChildren: () => import('./components/privacy/privacy.module').then(m => m.PrivacyModule),
            data: { preload: false },
          },
          { path: '404', redirectTo: '/app/index' },
          { path: '**', redirectTo: '/app/index' },
        ],
      },
    ],
  },

  {
    path: 'admin',
    canActivate: [AuthService],
    children: [
      {
        path: '',
        component: AppLayoutDefaultComponent,
        children: [
          {
            path: '',
            loadChildren: () => import('./components/admin/admin.module').then(m => m.AdminModule),
            data: { preload: false },
          },
        ],
      },
    ],
  },

  {
    path: 'company-admin',
    canActivate: [AuthService],
    children: [
      {
        path: '',
        component: AppLayoutDefaultComponent,
        children: [
          {
            path: '',
            loadChildren: () => import('./components/company-admin/company-admin.module').then(m => m.CompanyAdminModule),
            data: { preload: false },
          },
        ],
      },
    ],
  },

  {
    path: 'offer-preview',
    canActivate: [AuthGuestService],
    children: [
      {
        path: '',
        component: AppLayoutEmptyComponent,
        children: [
          {
            path: '',
            loadChildren: () => import('./components/offer/preview/offer-preview.module').then(m => m.OfferPreviewModule),
            data: { preload: false },
          },
        ],
      },
    ],
  },

  // {
  //   path: '',
  //   children: [
  //     {
  //       path: 'offer-preview',
  //       component: AppLayoutEmptyComponent,
  //       children: [
  //         {
  //           path: '',
  //           loadChildren: () => import('./components/offer-preview/offer.module').then(m => m.OfferModule),
  //           data: { preload: false },
  //         },
  //       ],
  //     },
  //   ],
  // },

  {
    path: '',
    component: AppLayoutGuestComponent,
    children: [
      {
        path: 'resetpassword',
        component: ForgotPasswordComponent,
      },
      {
        path: 'changepassword',
        component: ChangePasswordComponent,
      },
      {
        path: 'login',
        canActivate: [AuthService],
        loadChildren: () => import('./components/login/login.module').then(m => m.LoginModule),
        data: { preload: true },
      },
      {
        path: 'register',
        loadChildren: () => import('./components/register/register.module').then(m => m.RegisterModule),
        data: { preload: true },
      },
      {
        path: 'setpassword',
        loadChildren: () => import('./components/set-password/setpassword.module').then(m => m.SetPasswordModule),
        data: { preload: false }
      },
      {
        path: '404',
        loadChildren: () => import('./components/contact/contact.module').then(m => m.ContactModule),
        data: { preload: false },
      },
      {
        path: '**',
        redirectTo: '/app/index',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
