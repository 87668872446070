import { Component, OnInit, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DialogContentComponent } from './popup-dialog.content.component';

@Component({
  selector: 'app-popup-dialog-btn',
  templateUrl: './popup-dialog.component.html',
  styleUrls: ['./popup-dialog.component.css']
})
export class PopupDialogComponent implements OnInit {

  @Input() yesOptionText: string = "";
  @Input() noOptionText: string = "";
  @Input() dialogTitle: string = "";
  @Input() message: string = "";
  @Input() saveClick: Function = () => {}
  @Input() closeClick: Function = () => {}
  @Input() class: string = "";
  @Input() icon: string = "";

  constructor(public dialog: MatDialog) {}

  openDialog() {
    const dialogRef = this.dialog.open(DialogContentComponent, {
      data: {
        dialogTitle: this.dialogTitle,
        message: this.message,
        yesOptionText: this.yesOptionText,
        noOptionText: this.noOptionText,
        save: this.saveClick,
        close: this.closeClick,
        icon: this.icon
      }
    });

    dialogRef.afterClosed().subscribe(() => {
      const popupBtn: HTMLElement = (document.querySelector('app-popup-dialog-btn button:focus') || document.querySelector('app-popup-dialog-btn a:focus')) as HTMLElement
      if(popupBtn){
        popupBtn.blur();
      }
    });
  }

  ngOnInit(): void {
  }

}
