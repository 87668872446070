import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ValidationService } from './validation.service';
import { InputFileComponent } from './inputfile/inputfile.component';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { FlexLayoutModule } from '@angular/flex-layout';
import { EmployeeDeleteDialogComponent } from '@app/shared/contact-dialogs/contact-company-dialog/employee-delete-dialog.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SafeHtmlPipe } from '@app/pipes/safehtml.pipe';
import { OfferListStatusDialogComponent } from '@app/components/offer/list/offer.list.status.dialog.component';
import { ConfirmGeneratePdfDialogComponent } from './confirm-generate-pdf-dialog/confirm-generate-pdf-dialog.component';
import { YoutubePlayerDialogComponent } from './youtube-player-dialog/youtube-player-dialog.component';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { NgxSpinnerModule } from "ngx-spinner";
import { PopupDialogComponent } from './popup-dialog/popup-dialog.component';
import { DialogContentComponent } from './popup-dialog/popup-dialog.content.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CalendarDateFormatter, CalendarModule, CalendarNativeDateFormatter, DateAdapter, DateFormatterParams } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
import { CustomDateFormatter } from './calendar/custom-date-formatter';
import { PlaylistDialogComponent } from './help-videos/playlist-dialog/playlist-dialog.component';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions, MatTooltipModule } from '@angular/material/tooltip';
import { QrCodeDialogComponent } from './qrcode-dialog/qrcode.dialog.component';
import { QRCodeModule } from 'angularx-qrcode';
import { ProfileContactDeleteDialogComponent } from '@app/shared/contact-dialogs/contact-list-delete.dialog.component';
import { UserLogDialogComponent } from '@app/shared/user-dialogs/user-log-dialog/user.log.dialog.component';
import { UserVersionsDialogComponent } from '@app/shared/user-dialogs/user-versions-dialog/user.versions.dialog.component';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { OfferLogDialogComponent } from '@app/shared/offer-dialogs/offer-log-dialog/offer.log.dialog.component';
import { OfferVersionsDialogComponent } from '@app/shared/offer-dialogs/offer-versions-dialog/offer.versions.dialog.component';
import { ContactLogDialogComponent } from '@app/shared/contact-dialogs/contact-log-dialog/contact.log.dialog.component';
import { ContactVersionsDialogComponent } from '@app/shared/contact-dialogs/contact-versions-dialog/contact.versions.dialog.component';
import { OfferStatusCommentDialogComponent } from '@app/shared/offer-dialogs/offer-status-comment-dialog/offer.status-comment.dialog.component';
import { UpdateContactpersonDialogComponent } from './contact-dialogs/update-contactperson-dialog/update-contactperson-dialog.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { SignatureDialogComponent } from './signature-dialog/signature.dialog.component';
import { CompanyUserListDropdownComponent } from './company-user/company-user-list-dropdown/company-user-list-dropdown.component';
import { DashboardOfferListComponent } from './dashboard/dashboard-offer-list/dashboard-offer-list.component';
import { ContactCompanyDialogComponent } from './contact-dialogs/contact-company-dialog/contact-company-dialog.component';
import { ContactPrivateDialogComponent } from './contact-dialogs/contact-private-dialog/contact-private-dialog.component';
import { ContactPrivateQualificationComponent } from './contact-dialogs/contact-private-dialog/contact-private-qualification/contact-private-qualification.component';
import { MatLegacyCheckboxDefaultOptions as MatCheckboxDefaultOptions, MatLegacyCheckboxModule as MatCheckboxModule, MAT_LEGACY_CHECKBOX_DEFAULT_OPTIONS as MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/legacy-checkbox';
import { ContactCompnayQualificationComponent } from './contact-dialogs/contact-company-dialog/contact-compnay-qualification/contact-compnay-qualification.component';
import { ContactIndividualDialogComponent } from './contact-dialogs/contact-individual-dialog/contact-individual-dialog.component';
import { distinctProductFilterPipe } from '@app/pipes/distinct-filter.pipe';
import { OfferTemplateLogDialogComponent } from './offertemplate-dialogs/offertemplate-log-dialog/offertemplate.log.dialog.component';
import { OfferTemplateVersionsDialogComponent } from './offertemplate-dialogs/offertemplate-versions-dialog/offertemplate.versions.dialog.component';
import { SelectSearchComponent } from './elements/select-search/select-search.component';
import { OfferTemplateListDialogComponent } from '@app/shared/offertemplate-dialogs/offertemplate-list-dialog/offertemplate.list.dialog.component'
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { BlockTemplatePipe } from '@app/pipes/pipe.blocktemplate';
import { MtxDatetimepickerModule } from '@ng-matero/extensions/datetimepicker';
import { FileInputComponent } from './file-input/file-input.component';
import { CompanyBlockDetailV4DialogComponent } from './blocks/dialogs/company-block-detail-v4-dialog/company-block-detail-v4-dialog.component';
import { QuillModule } from 'ngx-quill';
import { BlockDetailAiComponent } from './blocks/block-detail-ai/block-detail-ai.component';
import { HelpVideoComponent } from './help-videos/help-video/help-video.component';
import {TranslateModule} from '@ngx-translate/core';

// class CustomDateFormatter extends CalendarNativeDateFormatter {

//   // http://plnkr.co/edit/INtKoU2Tony08Soqy8x7?p=preview&preview
//   public dayViewHour({ date, locale }: DateFormatterParams): string {
//     return new Intl.DateTimeFormat('de', {
//       hour: 'numeric',
//       minute: 'numeric'
//     }).format(date);
//   }

// }
export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
    showDelay: 0,
    hideDelay: 0,
    touchendHideDelay: 1500,
    disableTooltipInteractivity:true 
};

export function momentAdapterFactory() {
  return adapterFactory(moment);
};

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatCardModule,
        MatInputModule,
        MatSelectModule,
        FlexLayoutModule,
        MatDialogModule,
        MatRadioModule,
        MatTabsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        YouTubePlayerModule,
        NgxSpinnerModule,
        FontAwesomeModule,
        MatTooltipModule,
        CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory }, {
            dateFormatter: {
                provide: CalendarDateFormatter,
                useClass: CustomDateFormatter,
            }
        }),
        QRCodeModule,
        MatTableModule,
        MatPaginatorModule,
        NgxMatSelectSearchModule,
        AngularSignaturePadModule,
        MatCheckboxModule,
        CodemirrorModule,
        MtxDatetimepickerModule,
        QuillModule.forRoot(),

    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [ValidationService,
        { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
        { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { clickAction: 'check' } as MatCheckboxDefaultOptions },
        distinctProductFilterPipe,
        { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults }
    ],
    declarations: [InputFileComponent,
        EmployeeDeleteDialogComponent,
        SafeHtmlPipe,
        OfferListStatusDialogComponent,
        ConfirmGeneratePdfDialogComponent,
        YoutubePlayerDialogComponent,
        PopupDialogComponent,
        DialogContentComponent,
        PlaylistDialogComponent,
        QrCodeDialogComponent,
        ProfileContactDeleteDialogComponent,
        UserLogDialogComponent,
        UserVersionsDialogComponent,
        OfferLogDialogComponent,
        OfferVersionsDialogComponent,
        OfferTemplateLogDialogComponent,
        OfferTemplateVersionsDialogComponent,
        ContactLogDialogComponent,
        ContactVersionsDialogComponent,
        OfferStatusCommentDialogComponent,
        UpdateContactpersonDialogComponent,
        SignatureDialogComponent,
        CompanyUserListDropdownComponent,
        DashboardOfferListComponent,
        ContactCompanyDialogComponent,
        ContactPrivateDialogComponent,
        ContactPrivateQualificationComponent,
        ContactCompnayQualificationComponent,
        ContactIndividualDialogComponent,
        distinctProductFilterPipe,
        SelectSearchComponent,
        OfferTemplateListDialogComponent,
        BlockTemplatePipe,
        FileInputComponent,
        CompanyBlockDetailV4DialogComponent,
        BlockDetailAiComponent,
        HelpVideoComponent,
    ],
    exports: [InputFileComponent,
        SafeHtmlPipe,
        OfferListStatusDialogComponent,
        ConfirmGeneratePdfDialogComponent,
        YoutubePlayerDialogComponent,
        NgxSpinnerModule,
        PopupDialogComponent,
        DialogContentComponent,
        FontAwesomeModule,
        CalendarModule,
        QrCodeDialogComponent,
        ProfileContactDeleteDialogComponent,
        CompanyUserListDropdownComponent,
        DashboardOfferListComponent,
        ContactCompanyDialogComponent,
        ContactPrivateDialogComponent,
        distinctProductFilterPipe,
        SelectSearchComponent,
        CodemirrorModule,
        MatTooltipModule,
        BlockTemplatePipe,
        FileInputComponent,
        CompanyBlockDetailV4DialogComponent,
        BlockDetailAiComponent,
        HelpVideoComponent,
        TranslateModule,
    ]
})
export class SharedModule { }
