import { ApiResponse, ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { Offer } from '../property/offer';
import { Blockgroup } from '../property/blockgroup';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { NotificationService } from '@app/service/notification.service';
import { Block } from '../property/block';
import { DocListModel } from '@app/property/doc-list-model';
import { AuthService } from '@app/service/auth.service';

@Injectable()
export class OfferService extends ApiService {
  constructor(
    protected override http: HttpClient,
    private notificationService: NotificationService,
    private authService: AuthService,
  ) {
    super(http);
  }

  getById(id: string): Promise<Offer> {
    return this.http
      .get<ApiResponse>(`${this.url}offers/${id}`, this.options)
      .toPromise()
      .then((response) => {
        const tmp: any = Object.assign({}, response.data);
        const offer = response.data as Offer;
        offer.contact = JSON.parse(tmp.contact);
        /* Convert to blocks */
        offer.blocks = offer.blocks.map((b) => Block.fromJSON(b));
        return Promise.resolve(offer);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getAll(options = {}): Promise<Offer[]> {
    let params = null;
    if (options) {
      params = Object.keys(options)
        .map(
          (k) =>
            `${encodeURIComponent(k)}=${encodeURIComponent(
              typeof options[k] === 'object' ? JSON.stringify(options[k]) : options[k],
            )}`,
        )
        .join('&');
    }

    return this.http
      .get<ApiResponse>(`${this.url}offers/${params !== '' ? '?' + params : ''}`, this.options)
      .toPromise()
      .then((response) => {
        const offers = response.data as Offer[];
        return Promise.resolve(offers);
      });
  }

  getDocListModel(params: HttpParams = new HttpParams()): Promise<ApiResponse> {
    return this.http
      .get<ApiResponse>(`${this.url}doc-list-model/`, { params })
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getAllDocListModel(): Promise<ApiResponse> {
    let params = new HttpParams();
    params = params.set('limit', '0');
    params = params.set('offset', '0');

    return this.http
      .get<ApiResponse>(`${this.url}doc-list-model/`, { params })
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  updateDocListModel(): Promise<Offer> {
    return this.http
      .put<ApiResponse>(`${this.url}doc-list-model/`, this.options)
      .toPromise()
      .then((response) => {
        this.notificationService.showNotification('Die Liste wurde aktualisiert. Bitte laden Sie die Seite neu.');
        return Promise.resolve(response.data as Offer);
      })
      .catch((error) => {
        this.notificationService.showNotification(error.error.errors.error);
        return Promise.reject(error);
      });
  }

  // convertOfferFields(): Promise<Offer> {
  //   return this.http
  //     .put<ApiResponse>(`${this.url}/v1/offers-convert/fileds/`, this.options)
  //     .toPromise()
  //     .then((response) => {
  //       return Promise.resolve(response.data as Offer);
  //     })
  //     .catch((error) => {
  //       this.notificationService.showNotification(error.error.errors.error);
  //       return Promise.reject(error);
  //     });
  // }

  getAllFromBin(): Promise<Offer[]> {
    return this.http
      .get<ApiResponse>(`${this.url}bin/offers`, this.options)
      .toPromise()
      .then((response) => {
        const offers = response.data as Offer[];
        return Promise.resolve(offers);
      });
  }

  async save(offer: Offer): Promise<Offer> {
    if (offer._id) {
      await this.log(offer._id, 'update', offer);
      return this.http
        .put<ApiResponse>(`${this.url}offers/${offer._id}`, offer, this.options)
        .toPromise()
        .then((response) => {
          return Promise.resolve(response.data as Offer);
        })
        .catch((error) => {
          this.notificationService.showNotification(error.error.errors.error);
          return Promise.reject(error);
        });
    }
    if (!offer.status) {
      offer.status = 'draft';
    }
    return this.http
      .post<ApiResponse>(`${this.url}offers/`, offer, this.options)
      .toPromise()
      .then((response) => {
        this.log(response.data['_id'], 'create');
        return Promise.resolve(response.data as Offer);
      })
      .catch((error) => {
        this.notificationService.showNotification(error.error.errors.error);
        return Promise.reject(error);
      });
  }

  async updateStatusBasedOnDoc(offer: Offer, versionComment = ''): Promise<Offer> {
    if (offer._id) {
      await this.log(offer._id, 'changeStatus', offer, null, versionComment);
      return this.http
        .put<ApiResponse>(`${this.url}offerstatus/${offer._id}`, offer, this.options)
        .toPromise()
        .then((response) => {
          return Promise.resolve(response.data as Offer);
        })
        .catch((error) => {
          this.notificationService.showNotification(error.error.errors.error);
          return Promise.reject(error);
        });
    }
  }

  async updateStatusBasedOnModel(element: DocListModel, versionComment = '', withMajorVersion = true): Promise<Offer> {
    if (element.offer) {
      await this.log(element.offer, 'changeStatus', element, null, versionComment, withMajorVersion);
      return this.http
        .put<ApiResponse>(`${this.url}offerstatus/${element.offer}`, element, this.options)
        .toPromise()
        .then((response) => {
          return Promise.resolve(response.data as Offer);
        })
        .catch((error) => {
          this.notificationService.showNotification(error.error.errors.error);
          return Promise.reject(error);
        });
    }
  }

  async updateComment(offer: Offer): Promise<Offer> {
    if (offer._id) {
      await this.log(offer._id, 'update', offer);
      return this.http
        .put<ApiResponse>(`${this.url}offercomment/${offer._id}`, offer, this.options)
        .toPromise()
        .then((response) => {
          return Promise.resolve(response.data as Offer);
        })
        .catch((error) => {
          this.notificationService.showNotification(error.error.errors.error);
          return Promise.reject(error);
        });
    }
  }

  getPdf(offer: Offer): any {
    const headers = new Headers();
    headers.append('Accept', 'application/pdf');
    return this.http
      .get<ApiResponse>(`${this.url}offers/pdf/${offer._id}`, this.options)
      .toPromise()
      .then((response) => {
        // TODO: stream file

        // let offer = response.json().data as Offer
        return Promise.resolve(offer);
      });
  }

  markToDeleteById(id: string) {
    return this.http
      .put(`${this.url}bin/offers/${id}`, { toDelete: true }, this.options)
      .toPromise()
      .then((response) => {
        this.log(id, 'delete');
        return Promise.resolve(true);
      });
  }

  unMarkToDeleteById(id: string) {
    return this.http
      .put(`${this.url}bin/offers/${id}`, { toDelete: false }, this.options)
      .toPromise()
      .then(async (response) => {
        await this.log(id, 'recover');
        return Promise.resolve(true);
      });
  }

  deleteById(id: string) {
    return this.http
      .delete(`${this.url}bin/offers/${id}`, this.options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(true);
      });
  }

  getDownloadToken() {
    return this.http
      .get<ApiResponse>(`${this.url}offers/getPdfToken`, this.options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response.data);
      });
  }

  getNextOfferNumber(): Promise<number> {
    return this.http
      .get<ApiResponse>(`${this.url}nextnumber/offer`, this.options)
      .toPromise()
      .then((response) => {
        const nextNumber = response.data['nextOfferNumber'] as number;
        return Promise.resolve(nextNumber);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getLog(offerid) {
    return this.http
      .get<ApiResponse>(`${this.url}offerlog/${offerid}`, this.options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response.data);
      });
  }

  async restoreVersion(versionId, offerId) {
    await this.log(offerId, 'restore', null, versionId);

    const reqBody = {
      versionId,
      offerId,
    };

    return this.http
      .post<ApiResponse>(`${this.url}offerrestore/`, reqBody, this.options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        console.log(error.error.errors);
        return Promise.reject(error.error.errors);
      });
  }

  async versioningLegacySupport() {
    return this.http
      .post<ApiResponse>(`${this.url}versioningLegacySupport/`, this.options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        console.log(error.error.errors);
        return Promise.reject(error.error.errors);
      });
  }

  async log(offerId, logtype, newOffer = null, versionId = null, versionComment = '', withMajorVersion = true) {
    const currentUser = await this.authService.getCurrentUser();

    const reqBody = {
      logtype,
      currentuser: currentUser._id,
      logofferid: offerId,
      newOffer,
      versionId,
      versionComment,
      withMajorVersion,
    };

    return this.http
      .post<ApiResponse>(`${this.url}offerlog/`, reqBody, this.options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        console.log(error.error.errors);
        return Promise.reject(error.error.errors);
      });
  }

  /**
   * Saves users as objects in logs { firstname: ..., lastname: ... } instead of the id of the user
   * @returns true if success otherwise false
   */
  async updateLogs() {
    const currentUser = await this.authService.getCurrentUser();

    const reqBody = {
      currentuser: currentUser._id,
    };

    return this.http
      .post<ApiResponse>(`${this.url}update-logs/`, reqBody, this.options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        console.log(error.error.errors);
        return Promise.reject(error.error.errors);
      });
  }

  async checkPdfHash(id: string, fileId) {
    const currentUser = await this.authService.getCurrentUser();

    const reqBody = {
      fileId,
      currentUser: currentUser._id,
    };
    return this.http
      .post<ApiResponse>(`${this.url}offers/check-pdf-hash/${id}`, reqBody, this.options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  // async createUnchangeablePdfLegacySupport() {
  //   return this.http
  //     .get<ApiResponse>(`${this.url}offers/createunchangeablepdflegacysupport/`, this.options)
  //     .toPromise()
  //     .then((response) => {
  //       return Promise.resolve(response.data);
  //     })
  //     .catch((error) => {
  //       console.log(error.error.errors);
  //       return Promise.reject(error.error.errors);
  //     });
  // }

  async createUnchangeablePdf(id: string) {
    const currentUser = await this.authService.getCurrentUser();
    const reqBody = {
      currentUser: currentUser._id,
    };

    return this.http
      .post<ApiResponse>(`${this.url}offers/createunchangeablepdf/${id}`, reqBody, this.options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        console.log(error.error.errors);
        return Promise.reject(error.error.errors);
      });
  }

  async getContactDocuments(id: string): Promise<Offer[]> {
    try {
      const response = await this.http
        .get<ApiResponse>(`${this.url}offers/contact-documents/${id}`, this.options)
        .toPromise();
      return Promise.resolve(response.data as Offer[]);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
