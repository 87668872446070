import { Block } from './block';
import * as $ from 'jquery';

export class Page {
  static PAGECOUNTER = 0;

  static readonly A4_HEIGHT = 29.7;
  static readonly UNUSABLE_HEIGHT_FURTHER_PAGES = 0.1;
  static readonly UNUSABLE_HEIGHT_FIRST_PAGE = 14.5;
  // static readonly PAGE_HEIGHT: number = Page.A4_HEIGHT - Page.UNUSABLE_HEIGHT_ALL_PAGES;

  pagenumber: number;
  currentHeight: number;
  elements: Block[];
  isFirstPage: boolean;

  constructor(isFirst = true) {
    this.pagenumber = Page.PAGECOUNTER++;
    // this.currentHeight = this.getInitialPageHeight();
    this.currentHeight = isFirst ? Page.UNUSABLE_HEIGHT_FIRST_PAGE : Page.UNUSABLE_HEIGHT_FURTHER_PAGES;
    this.isFirstPage = isFirst;

    // console.log('Pagenr: ', this.pagenumber);
    // console.log('CurrentHeight: ', this.currentHeight);
    //console.log('RemainingHeight: ', Page.PAGE_HEIGHT - this.currentHeight);
    
    this.elements = Array<Block>();
  }
  static px2cm(px): number {
    const d = $('<div/>')
      .css({ position: 'absolute', top: '-1000cm', left: '-1000cm', height: '1000cm', width: '1000cm' })
      .appendTo('body');
    const px_per_cm = d.height() / 1000;
    d.remove();
    return px / px_per_cm;
  }
  static cm2px(cm): number {
    const d = $('<div/>')
      .css({ position: 'absolute', top: '-1000cm', left: '-1000cm', height: '1000cm', width: '1000cm' })
      .appendTo('body');
    const px_per_cm = d.height() / 1000;
    d.remove();
    return px_per_cm * cm;
  }

  usableHeight(): number {
    return Page.A4_HEIGHT - this.getInitialPageHeight() - this.elements.reduce((height, element) => height + element.size.height, 0);
  }

  getInitialPageHeight(): number {
    return this.isFirstPage ? Page.UNUSABLE_HEIGHT_FIRST_PAGE : Page.UNUSABLE_HEIGHT_FURTHER_PAGES;
  }

  clear(): void {
    this.elements = new Array<Block>();
    this.currentHeight = this.getInitialPageHeight();
  }

  isEmpty(): boolean {
    return this.elements.length === 0;
  }

  addElement(element: Block): boolean {
    const pageSizePixel = Page.cm2px(Page.A4_HEIGHT);
    if (element && element.fields && element.fields && element.size) {
      // let elmSize = (element.size.height / pageSizePixel) * Page.A4_HEIGHT;

      // console.log('ELM pixel: ', elmSize);
      // console.log('PageHeight: ', Page.PAGE_HEIGHT);
      // console.log('CurrentHeight: ', this.currentHeight);
      // console.log('Element Height: ', elmSize);
      // console.log('Remaining height: ', Page.PAGE_HEIGHT - this.currentHeight - elmSize);

      // if (this.currentHeight + element.size.height <= Page.A4_HEIGHT) {
      //   if (element.pageBreak && element.pageBreak === true) {
      //     element.size.height = Page.A4_HEIGHT - this.currentHeight;
      //   }
      //   this.currentHeight += element.size.height;
        element.pageNumber = this.pagenumber;
        this.elements.push(element);
        return true;
      // }
    }
    // element.pageNumber = 0;
    return false;
  }
  addElementOnLoad(element: Block): boolean {
    this.elements.push(element);
    return true;
  }
  async deleteElement(block: Block): Promise<boolean> {
    return this.deleteElementByIndex(this.elements.indexOf(block));
  }
  async deleteElementByIndex(index: number): Promise<boolean> {
    if (index > -1 && index <= this.elements.length + 1) {
      this.elements.splice(index, 1);
      return true;
    }
    return false;
  }
  getElements(): Block[] {
    return this.elements;
  }
}
