import { Injectable } from '@angular/core';
import { Block } from '@app/property/block';
import { Offer } from '@app/property/offer';
import { Page } from '@app/property/page';
import * as _ from 'lodash';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})export class OfferDetailPageService {

  private addElementAnnounced = new Subject<Page[]>();
  public addElementAnnounced$ = this.addElementAnnounced.asObservable();

  private addDialogElementBlock = new Subject<Block>();
  public addDialogElementBlock$ = this.addDialogElementBlock.asObservable();

  // private pages = {
  //   coverpage: [],
  //   writeto: [],
  //   offer: [],
  //   form: []
  // };

  private blocks = {
    coverpage: [],
    writeto: [],
    offer: [],
    form: [],
  };

  private cats = {
    coverpage: false,
    writeto: false,
    offer: false,
    form:  false,
  }

  dropLists = {
    coverpage: ['pagelist0'],
    writeto: ['pagelist0'],
    offer: ['pagelist0'],
    form: ['pagelist0']
  }
  public currentActiveCat: string;

  private formBlocksAnnounced = new Subject<Block[]>();
  public formBlocksAnnounced$ = this.formBlocksAnnounced.asObservable();

  constructor(

  ) {
    // this.pages.coverpage = Array<Page>()
    // this.pages.writeto = Array<Page>();
    // this.pages.offer = Array<Page>();
    // this.pages.form = Array<Page>();

    this.blocks.coverpage = Array<Block>();
    this.blocks.writeto = Array<Block>();
    this.blocks.offer = Array<Block>();
    this.blocks.form = Array<Block>();

    this.currentActiveCat = 'writeto';
  }
  getBlocks(): Block[] {
    return this.blocks[this.currentActiveCat];
  }

  public getBlocksByCat(cat: string): Block[] {
    return this.blocks[cat];
  }

  public hasElementsBy(cat: string){
    return this.blocks[cat].length > 0;
  }

  getAllBlocks(): Block[] {
    const list: Block[] = [];
    this.blocks.coverpage.forEach(element => {
      list.push(element);
    })
    this.blocks.writeto.forEach(element => {
      list.push(element);
    });
    this.blocks.offer.forEach(element => {
      list.push(element);
    });
    this.blocks.form.forEach(element => {
      list.push(element);
    });
    return list;
  }

  // getPages(): Page[] {
  //   return this.pages[this.currentActiveCat];
  // }

  public get catsStatus() {
    return this.cats;
  }

  public updateCatStatus(cat: string, status: boolean) {
    this.cats[cat] = status;
    
    if (status) {
      this.currentActiveCat = cat;
      return false;
    }

    if (cat !== this.currentActiveCat) return false;

    this.switchToNextCat();
  }

  switchToNextCat(){
    this.currentActiveCat = 'writeto'; 

    // return true;

    if (this.cats.writeto) { 
      return true; 
    }
    if (this.cats.offer) {
      this.currentActiveCat = 'offer';
      return true;
    }
    if (this.cats.coverpage) {
      this.currentActiveCat = 'coverpage';
      return true;
    }
    if (this.cats.form) {
      this.currentActiveCat = 'form';
      return true;
    }

    this.currentActiveCat = ''
  }

  switchToCat(category: string) {
    if (this.cats[category]) {
      this.currentActiveCat = category;
    } else {
      this.currentActiveCat = 'writeto';
    }
  }

  /**
   * Clears elements
   */
  clearElements(): void {
    // this.pages.coverpage = [];
    // this.pages.writeto = [];
    // this.pages.offer = [];
    // this.pages.form = [];

    this.blocks.coverpage = [];
    this.blocks.writeto = [];
    this.blocks.offer = [];
    this.blocks.form = [];

    this.cats.coverpage = false;
    this.cats.writeto = false;
    this.cats.offer = false;
    this.cats.form = false;

    Page.PAGECOUNTER = 0;
    this.currentActiveCat = 'writeto';
    // this.notify();
  }
  /**
   * Notifys offer detail service
   */
  // notify(category: string): void {
  //   this.addElementAnnounced.next(this.pages[category]);
  // }

  /**
   * Gets total elements count
   * @returns total elements count 
   */
  // getTotalElementsCount(): number {
  //   let count = 0;
  //   this.pages[this.currentActiveCat].forEach(page => (count += page.elements.length));
  //   return count;
  // }
  /**
   * Moves block up
   * @param block 
   */
  moveUp(block: Block) {
    const category = this.currentActiveCat;
    const index = this.blocks[category].indexOf(block);
    if (index > 0) {
      this.blocks[category][index] = this.blocks[category][index - 1];
      this.blocks[category][index - 1] = block;
      this.reloadPages(category);
    }
  }
  /**
   * Moves block down
   * @param block 
   */
  moveDown(block: Block) {
    const category = this.currentActiveCat;
    const index = this.blocks[category].indexOf(block);
    if (index < this.blocks[category].length - 1) {
      this.blocks[category][index] = this.blocks[category][index + 1];
      this.blocks[category][index + 1] = block;
      this.reloadPages(category);
    }
  }

  /**
   * Concat all pages and then re-add each of the elements to the pages
   * @param callback Callback that gets executed before re-adding elements to page, has acces to elements
   */
  // async reloadPages(callback: (blocks: Block[]) => Promise<void> = null, category: string): Promise<void> {
  async reloadPages(category: string): Promise<void> {
    // this.pages[category].forEach(p => p.clear());
    // let pageIndex = 0;
    // this.dropLists[category] = [`pagelist${pageIndex}`];
    this.blocks[category].forEach((block, index) => {
      if ( index === this.blocks[category].length - 1) { block.last = true; }
      if ( index === 0) { block.last = true; }

      // if (!this.pages[category][pageIndex]) {
      //   this.pages[category][pageIndex] = new Page(false);
      // }

      // if (block?.size?.height && (this.pages[category][pageIndex].usableHeight() > block.size.height)) {
      //   this.pages[category][pageIndex].addElement(block);
      // } else {
      //   pageIndex = pageIndex + 1;
      //   if (!this.pages[category][pageIndex]) {
      //     this.pages[category].push(new Page(false));
      //   }
      //   this.pages[category][pageIndex].addElement(block);
      //   this.dropLists[category].push(`pagelist${pageIndex}`);
      // }
      // block.pageNumber = pageIndex;

      // if (block.pageBreak) {
      //   pageIndex = pageIndex + 1;
      //   if (!this.pages[category][pageIndex]) {
      //     this.pages[category].push(new Page(false));
      //   }
      //   // this.pages[category][pageIndex].addElement(block);
      //   this.dropLists[category].push(`pagelist${pageIndex}`);
      // }
    });

    // while ((pageIndex + 1) < this.pages[category].length) {
    //   this.pages[category].splice(pageIndex + 1);
    //   pageIndex = pageIndex + 1;
    // }
  }

  /**
   * Sets order in blocks of all pages
   */
  async setBlockOrder(blocks: Block[]): Promise<void> {
    const iterateBlocks = async (blocks: Block[] = [], index = 0) => {

      if (index >= blocks.length) {
        return Promise.resolve();
      }
      const block = blocks[index];
      block.order = index;

      return await iterateBlocks(blocks, index + 1);
    };
    return iterateBlocks(blocks, 0);
  }
  /**
   * Inserts block before other block 
   * @param element 
   * @param beforeElement 
   */
  async insertBefore(element: Block, beforeElement: Block) {
    const category = this.currentActiveCat;
    const elementCopy: Block = _.cloneDeep(element);
    if (!elementCopy.size) {
      elementCopy.size = { height: 0, width: 0 };
    }

    const index = this.blocks[category].indexOf(beforeElement);
    this.blocks[category].splice(index, 0, elementCopy);

    // this.pages[category][this.pages[category].length - 1].addElement(elementCopy);
    //this.offerDetailService.setUnsaved = true;
    // this.notify(category);

  }
  /**
   * Inserts block after other block
   * @param element 
   * @param afterElement 
   */
  async insertAfter(element: Block, afterElement: Block) {
    const category = this.currentActiveCat;
    const elementCopy: Block = _.cloneDeep(element);
    if (!elementCopy.size) {
      elementCopy.size = { height: 0.1, width: 0 };
    }

    if (afterElement) {
      const index = this.blocks[category].indexOf(afterElement);
      this.blocks[category].splice(index + 1, 0, elementCopy);
      // this.pages[category][this.pages[category].length - 1].addElement(elementCopy);
      //this.offerDetailService.setUnsaved = true;
      // this.notify(category);
    } else {
      this.addElement(element, category);
    }

  }

  /**
   * Adds blocks
   * @param elements 
   * @returns elements 
   */
  addElements(elements: Block[], category: string): Promise<any> {
    const chain = elements.map((element) => {
      return this.addElement(element, category);
    });
    return Promise.all(chain);
  }
  /**
   * Adds block
   * @param e 
   * @param [insertAtEnd] 
   * @returns element 
   */
  addElement(e: Block, category: string): Promise<void> {
    //const list = 
    return new Promise((fulfill, reject) => {
      const element: Block = _.cloneDeep(e);
      this.blocks[category].push(element);
      (async () => {
        // if (this.pages[category].length === 0) {
        //   this.pages[category].push(new Page(true));
        // }
        // if (element && element.fields && element.template) {
        //   //this.offerDetailService.setUnsaved = true;
        //   if (!element.size) {
        //     element.size = { height: 0, width: 0 };
        //   }
        //   this.pages[category][this.pages[category].length - 1].addElement(element);
        //   //this.offerDetailService.setUnsaved = true;
        //   this.notify(category);
        //   return fulfill();
        // }
        return fulfill();
      })();
    });
  }

  /**
   * Adds blocks
   * @param elements 
   * @returns elements 
   */
   addElementsOnLoad(elements: Block[], category: string): Promise<any> {
    const chain = elements.map((element) => {
      return this.addElementOnLoad(element, category);
    });
    return Promise.all(chain);
  }
  /**
   * Adds block
   * @param e 
   * @param [insertAtEnd] 
   * @returns element 
   */
   addElementOnLoad(e: Block, category: string): Promise<void> {
    //const list = 
    return new Promise((fulfill, reject) => {
      const element: Block = _.cloneDeep(e);
      this.blocks[category].push(element);
      (async () => {
        // if (this.pages[category].length === 0) {
        //   this.pages[category].push(new Page(false));
        // }
        // if (element && element.fields && element.template) {
        //   //this.offerDetailService.setUnsaved = true;
        //   if (!element.size) {
        //     element.size = { height: 0, width: 0 };
        //   }
        //   if (element.pageNumber >= 0 && !this.pages[category][element.pageNumber]) {
        //     this.pages[category][element.pageNumber] = new Page(false);
        //     this.pages[category][element.pageNumber].addElementOnLoad(element);
        //   } else {
        //     this.pages[category][this.pages[category].length - 1].addElementOnLoad(element);
        //   }
        //   //this.offerDetailService.setUnsaved = true;
        //   this.notify(category);
        //   return fulfill();
        // }
        return fulfill();
      })();
    });
  }

  /**
   * Deletes block
   * @param element 
   */
  async deleteElement(element: Block) {
    const category = this.currentActiveCat;
    const index = this.blocks[category].indexOf(element);
    if (index > -1) {
      this.blocks[category].splice(index, 1);
      // this.notify(category);
      this.reloadPages(category);
    }
  }
  /**
   * Deletes block
   * @param element 
   */
  async removeElementsBy(category: string) {
      this.blocks[category] = [];
      // this.notify(category);
      this.reloadPages(category);
  }
  /**
   * Adds dialog element
   * @param block 
   */
  addDialogElement(block: Block): void {
    this.addDialogElementBlock.next(block);
  }

  load(offer: Offer, classicMode = false): void {
    if (classicMode) {
      this.loadClassic(offer);
    } else {
      this.loadNew(offer);
    }
  }

  loadNew(offer: Offer): void {
    this.clearElements();

    const coverpageBlocks = [];
    const writetoBlocks = [];
    const offerBlocks = [];
    const formBlocks = [];

    if (offer?.blocks) {
      for (const block of offer?.blocks) {
        if (block?.categorie === 'cover') {
          coverpageBlocks.push(block);
        }
        if (block?.categorie === 'writeto') {
          writetoBlocks.push(block);
        }
        if (block?.categorie === 'offer') {
          offerBlocks.push(block);
        }
        if (block?.categorie === 'form') {
          formBlocks.push(block);
        }
      }
    }

    // if (this.pages['coverpage'].length === 0) {
    //   this.pages['coverpage'].push(new Page(true));
    // }

    // if (this.pages['writeto'].length === 0) {
    //   this.pages['writeto'].push(new Page(true));
    // }

    // if (this.pages['offer'].length === 0) {
    //   this.pages['offer'].push(new Page(false))
    // }

    // if (this.pages['form'].length === 0) {
    //   this.pages['form'].push(new Page(false))
    // }

    if (coverpageBlocks.length > 0) {
      coverpageBlocks[coverpageBlocks.length - 1].last = true;
      (async () => {
        await this.addElementsOnLoad(coverpageBlocks.map(b => Block.fromJSON(b)), 'coverpage');
      })();
      this.cats.coverpage = true;
    }

    if (writetoBlocks.length > 0) {
      writetoBlocks[writetoBlocks.length - 1].last = true;
      (async () => {
        await this.addElementsOnLoad(writetoBlocks.map(b => Block.fromJSON(b)), 'writeto');
      })();
      this.cats.writeto = true;
    }

    if (offerBlocks?.length > 0) {
      offerBlocks[offerBlocks.length - 1].last = true;
      (async () => {
        await this.addElementsOnLoad(offerBlocks.map(b => Block.fromJSON(b)), 'offer');
      })();
      this.cats.offer = true;
    }

    if (formBlocks?.length > 0) {

      this.formBlocksAnnounced.next(formBlocks);

      formBlocks[formBlocks.length - 1].last = true;
      (async () => {
        await this.addElementsOnLoad(formBlocks.map(b => Block.fromJSON(b)), 'form');
      })();
      this.cats.form = true;
    }

    if (!this.cats.coverpage && !this.cats.writeto && !this.cats.offer && !offer?.contact?.company){
      this.cats.coverpage = true;
      this.cats.writeto = true;
      this.cats.offer = true;
      this.cats.offer = true;
    }

    //if (offer?.contact?.company){
      this.cats.writeto = true;
    //}

    if (offer.coverPage || offer.coverImage){
      this.cats.coverpage = true;
    }

    this.switchToNextCat();
    // this.notify('writeto');
  }

  loadClassic(offer: Offer): void {
    this.clearElements();

    // if (this.pages['writeto'].length === 0) {
    //   this.pages['writeto'].push(new Page(true));
    // }
    
    if (offer?.blocks.length > 0) {
      offer.blocks[offer.blocks.length - 1].last = true;
      (async () => {
        // await this.setBlockOrder(this.offer.blocks);
        await this.addElements(offer?.blocks.map(b => Block.fromJSON(b)), 'writeto');
      })();
      this.cats.writeto = true;
    }

    this.switchToNextCat();
    // this.notify('writeto');
  }

  // async updateFormBlocks(blocks: Block[]){
  //   await this.removeElementsBy('form');
  //   await this.addElements(blocks.map(b => Block.fromJSON(b)), 'form');
  //   this.triggerNextFormBlocks();
  //   this.reloadPages('form');
  // }
  
  async updateBlocks(cat:string, blocks: Block[]){
    await this.removeElementsBy(cat);
    await this.addElements(blocks.map(b => Block.fromJSON(b)), cat);
    
    if ( cat === 'form') this.triggerNextFormBlocks();
    
    this.reloadPages(cat);
  }
  
  triggerNextFormBlocks(): void {
    this.formBlocksAnnounced.next(this.getBlocksByCat('form'));
  }

  // clearBlocks(blocks): void {
  //   blocks.forEach(block => {
  //     block.fields.forEach(field => {
        
  //     });
  //   });
  // }
}
