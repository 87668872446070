import { ApiResponse, ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { AuthService } from '@app/service/auth.service';
import { User } from '@app/property/user';
import { Observable } from 'rxjs';
import { HelpVideo } from '@app/property/helpvideo';

@Injectable()
export class HelpVideoService extends ApiService {
  constructor(
    protected override http: HttpClient, 
    private authService: AuthService) {
    super(http);
  }

  getById(id: string): Promise<HelpVideo> {
    return this.http
      .get<ApiResponse>(`${this.url}helpvideo/${id}`, this.options)
      .toPromise()
      .then((response) => {
        const helpVideo = response.data as HelpVideo;
        return Promise.resolve(helpVideo);
      });
  }

  getAll(): Promise<HelpVideo[]> {
    return this.http
      .get<ApiResponse>(`${this.url}helpvideo/`, this.options)
      .toPromise()
      .then((response) => {
        const helpVideo = response.data as HelpVideo[];
        return Promise.resolve(helpVideo);
      });
  }

  getByPosition(options = {}): Promise<HelpVideo[]> {
    let params = null;
    if (options) {
      params = Object
        .keys(options)
        .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(typeof options[k] === 'object' ? JSON.stringify(options[k]) : options[k])}`)
        .join('&');
    }

    return this.http
      .get<ApiResponse>(`${this.url}helpvideo/${params !== '' ? '?' + params : ''}`, this.options)
      .toPromise()
      .then((response) => {
        const hv = response.data as HelpVideo[];
        return Promise.resolve(hv);
      });
  }

  async deleteById(id: string): Promise<boolean> {
    return this.http
      .delete(`${this.url}helpvideo/${id}`, this.options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(true);
      });
  }

  async save(form: FormGroup): Promise<HelpVideo> {
    const formData: any = form.value;

    if (formData._id) {
      return this.http
        .put<ApiResponse>(`${this.url}helpvideo/${formData._id}`, formData, this.options)
        .toPromise()
        .then((response) => {
          return Promise.resolve(response.data as HelpVideo);
        })
        .catch((error) => {
          console.error(error);
          return Promise.reject(error);
        });
    }

    return this.http
      .post<ApiResponse>(`${this.url}helpvideo/`, formData, this.options)
      .toPromise()
      .then((response) => {
        console.log('response: ', response);
        return Promise.resolve(response.data as HelpVideo);
      })
      .catch((error) => {
        console.log(error.error.errors);
        return Promise.reject(error.error.errors);
      });
  }
}
