import { Contact } from '@app/property/contact';

export class ContactPerson {
    companyId?: string;
    company?: any;
    department?: string;
    title?: string;
    salutation: string;
    fullsalutation?: string;
    firstname: string;
    lastname: string;
    phone: string;
    mobile?: string;
    fax?: string;
    email?: string;
}
