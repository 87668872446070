import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from './service/auth.service';
import { Router } from '@angular/router';
import { NotificationService } from './service/notification.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private _language$: Subscription | undefined;

  constructor(
    private router: Router,
    private notificationService: NotificationService,
    public snackBar: MatSnackBar,
    private translate: TranslateService,
    private authService: AuthService,
  ) {
    this.translate.addLangs(['de-stb', 'de-gen']);
    this.translate.setDefaultLang('de-stb');
  }

  ngOnInit(): void {
    this._language$ = this.authService.language.subscribe((language) => {
      this.translate.use(language);
    });

    const preBootstrap = document.getElementById('pre-bootstrap');
    preBootstrap.className = 'fadeout';

    setTimeout(function removeLoadingScreen() {
      preBootstrap.parentNode.removeChild(preBootstrap);
    }, 500);
  }

  ngOnDestroy(): void {
    this._language$?.unsubscribe();
  }
}
