import { ApiResponse, ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { AuthService } from '@app/service/auth.service';
import { User } from '@app/property/user';
import { Observable } from 'rxjs';
import { Advantage } from '@app/property/advantage';
import {NotificationService} from '@app/service/notification.service';

@Injectable()
export class AdvantageService extends ApiService {
  constructor(
    protected override http: HttpClient, 
    private authService: AuthService, private notificationService: NotificationService) {
    super(http);
  }

  getById(id: string): Promise<Advantage> {
    return this.http
      .get<ApiResponse>(`${this.url}advantage/${id}`, this.options)
      .toPromise()
      .then((response) => {
        const advantage = response.data as Advantage;
        return Promise.resolve(advantage);
      });
  }

  getAll(): Promise<Advantage[]> {
    return this.http
      .get<ApiResponse>(`${this.url}advantage/`, this.options)
      .toPromise()
      .then((response) => {
        const advantage = response.data as Advantage[];
        return Promise.resolve(advantage);
      });
  }

  async deleteById(id: string): Promise<boolean> {
    return this.http
      .delete(`${this.url}advantage/${id}`, this.options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(true);
      });
  }

  async save(form: FormGroup): Promise<Advantage> {
    const formData: any = form.value;

    if (formData._id) {
      return this.http
        .put<ApiResponse>(`${this.url}advantage/${formData._id}`, formData, this.options)
        .toPromise()
        .then((response) => {
          this.notificationService.showNotification('Änderungen wurden gespeichert');
          return Promise.resolve(response.data as Advantage);
        })
        .catch((error) => {
          console.error(error);
          return Promise.reject(error);
        });
    }

    return this.http
      .post<ApiResponse>(`${this.url}advantage/`, formData, this.options)
      .toPromise()
      .then((response) => {
        console.log('response: ', response);
        this.notificationService.showNotification('Nutzen wurde angelegt');
        return Promise.resolve(response.data as Advantage);
      })
      .catch((error) => {
        console.log(error.error.errors);
        return Promise.reject(error.error.errors);
      });
  }
}
