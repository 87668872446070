<div fxLayout="row" fxLayoutAlign="center">
  <div fxFlex="100%">

    <div class="login-card">
      <mat-card fxLayout="column" fxLayoutAlign="center">

        <mat-card-title>Password zurücksetzen</mat-card-title>

        <mat-card-content>

          <div *ngIf="!success">
            <form [formGroup]="changePasswordForm" (ngSubmit)="submit()" fxLayout="row wrap" fxLayout.xs="column wrap" fxLayoutAlign="end"
              *ngIf="valid | async">
              <mat-form-field fxFlex.xs="100%" fxFlex="100%">
                <input matInput type="{{showPassword.password ? 'text' : 'password'}}" name="password" placeholder="Passwort" #password formControlName="password">
                <div class="validation-wrap" *ngIf="changePasswordForm.controls['password'].invalid && (changePasswordForm.controls['password'].dirty || changePasswordForm.controls['password'].touched)">
                  <div class="with-text" *ngIf="changePasswordForm.controls['password'].errors.maxlength">Maximum Zeichen 100</div>
                </div>
                <mat-icon style="cursor: pointer;" (click)="showHidePassword($event, 'password')" (touchstart)="showHidePassword($event, 'password')" matSuffix>{{showPassword.password ? 'visibility_off' : 'visibility'}}</mat-icon>
              </mat-form-field>
              <mat-form-field fxFlex.xs="100%" fxFlex="100%">
                <input matInput type="{{showPassword.confirmPassword ? 'text' : 'password'}}" name="confirmPassword" placeholder="Passwort bestätigen" #confirmPassword formControlName="confirmPassword">
                <div class="validation-wrap" *ngIf="changePasswordForm.controls['confirmPassword'].invalid && (changePasswordForm.controls['confirmPassword'].dirty || changePasswordForm.controls['confirmPassword'].touched)">
                  <div class="with-text" *ngIf="changePasswordForm.controls['confirmPassword'].errors.maxlength">Maximum Zeichen 100</div>
                </div>
                <mat-icon style="cursor: pointer;" (click)="showHidePassword($event, 'confirmPassword')" (touchstart)="showHidePassword(fal$eventse, 'confirmPassword')" matSuffix>{{showPassword.confirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
              </mat-form-field>

              <div class="error" *ngIf="changePasswordForm.get('confirmPassword').touched && changePasswordForm.get('confirmPassword').hasError('MatchPassword')">
                Passwörter stimmen nicht überein
              </div>
              <div class="error" *ngIf="changePasswordForm.get('password').touched && changePasswordForm.get('password').hasError('minlength')">
                Das Passwort muss zwischen 8 und 100 Zeichen enthalten
              </div>
              <div *ngFor="let err of passwordFormErrors">
                <div class="text-danger">{{err.msg}}</div>
              </div>
              <button mat-raised-button type="submit" title="Absenden" color="accent" [disabled]="!changePasswordForm.valid">Absenden</button>
            </form>
            <div *ngIf="!(valid | async)">
              <p>
                Token passt nicht zu dieser E-Mail-Adresse oder ist bereits abgelaufen. Bitte versuchen Sie es erneut!
              </p>
              <a mat-raised-button title="Login" href="/login" color="primary">Zurück zum Login</a>
            </div>
          </div>

          <div *ngIf="success">
            <p>
              Passwort erfolgreich geändert.
            </p>
            <a mat-raised-button title="Login" href="/login" color="primary">Zurück zum Login</a>
          </div>
          
        </mat-card-content>

      </mat-card>
    </div>
  </div>
</div>
