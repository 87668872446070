import { ApiResponse, ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@app/service/auth.service';
import { lastValueFrom, Observable } from 'rxjs';
import { Product } from '@app/property/product';
import { map } from 'rxjs/operators';

@Injectable()
export class MyProductService extends ApiService {


  constructor(
    protected override http: HttpClient,
    private authService: AuthService,
  ) {
    super(http);
  }

  getWithInjection(options = {}): Promise<Product[]> {
    let params = null;
    if (options) {
      params = Object
        .keys(options)
        .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(typeof options[k] === 'object' ? JSON.stringify(options[k]) : options[k])}`)
        .join('&');
    }

    return this.http
      .get<any>(`${this.url}myproduct/extra/withinjection/${params !== '' ? '?' + params : ''}`, this.options)
      .toPromise()
      .then((response) => {
        const products = response.data as Product[];
        return Promise.resolve(products);
      });
  }

  getForPriceTable(): Observable<Product[]> {
    return this.http
      .get<any>(`${this.url}myproduct/extra/pricetable`, this.options)
      .pipe(map(result => result.data))
  }

  getAll(): Promise<Product[]> {
    return this.http
      .get<any>(`${this.url}myproduct`, this.options)
      .toPromise()
      .then((response) => {
        const p = Array.prototype.map.call(response.data, (p) => {
          return p;
        }) as Product[];
        return Promise.resolve(p);
      });
  }

  getById(id: string): Promise<Product> {
    return this.http
      .get<any>(`${this.url}myproduct/${id}`, this.options)
      .toPromise()
      .then((response) => {
        const p = response.data as Product;
        return Promise.resolve(p);
      });
  }

  async save(product: Product): Promise<Product> {
    if (product._id) {
      return this.update(product);
    } else {
      return this.insert(product);
    }
  }

  async insert(product: Product): Promise<Product> {
    try {
      const response = this.http
        .post<any>(this.url + `myproduct`, product, this.options);
      
      const result = await lastValueFrom(response);
      
      if (!result) { return undefined; }

      return result.data as Product;
    } catch (error) {
      return undefined;
    }
  }

  async update(product: Product): Promise<Product> {
    try {
      const response = this.http
        .put<any>(this.url + `myproduct/${product._id}`, product, this.options);
      
      const result = await lastValueFrom(response);
      
      if (!result) { return undefined; }

      return result.data as Product;
    } catch (error) {
      return undefined;
    }
  }

  async copyToMyProduct(product: Product): Promise<Product> {
    try {
      const response = this.http
        .post<any>(this.url + `copyto/myproduct`, product, this.options);
      
      const result = await lastValueFrom(response);
      
      if (!result) { return undefined; }

      return result.data as Product;
    } catch (error) {
      return undefined;
    }
  }

  async restoreToOriginalProduct(product: Product): Promise<Product> {
    try {
      const response = this.http
        .post<any>(this.url + `restore-original/myproduct`, product, this.options);
      
      const result = await lastValueFrom(response);
      
      if (!result) { return undefined; }

      return result.data as Product;
    } catch (error) {
      return undefined;
    }
  }

  deleteById(id: string): Promise<ApiResponse> {
    return this.http
      .delete<any>(this.url + `myproduct/${id}`, this.options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      });
  }

  async toggleVisibility(productId: string, action): Promise<boolean> {
    try {
      const response = this.http
        .put<any>(this.url + `company-products`, {productId, action}, this.options);
      
      const result = await lastValueFrom(response);
      if (!result) { return false; }
      return true;
    } catch (error) {
      return false;
    }
  }
}